import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Button, Checkbox, Form, Input } from "antd";
import { AlertPopup } from "../../../components/common";
import { changePassword, checkAndUpdatePassword, resetPassword } from "../../../helpers/api/func";
import styled from "styled-components";
import Card from "antd/lib/card/Card";

interface FormProps {
  oldPassword: string;
  newPassword: string;
  reNewPassword: string;
}

const PasswordEl = styled(Input.Password)`
  padding: 0px;
  position: relative;
  input {
    padding: 4px 30px 4px 11px !important;
    & + span {
      position: absolute;
      width: 14px;
      height: 100%;
      right: 11px;
    }
  }
`;

const ChangePasswordForm: React.FC = () => {
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<string>("");
  const [alertMsg, setAlertMsg] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const params = useParams();
  const [form] = Form.useForm();

  const handleSubmit = async (values: FormProps) => {
    setLoading(true);
    try {
      await changePassword(values);
      setAlertOpen(true);
      setAlertType("success");
      setAlertMsg("Your password has been change successfully");
      form.resetFields(["oldPassword", "newPassword", "reNewPassword"]);
    } catch (error: any) {
      setAlertOpen(true);
      setAlertType("error");
      setAlertMsg(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseAlert = () => {
    setAlertOpen(false);
    setAlertType("");
    setAlertMsg("");
  };

  return (
    <Card>
      <Form
        form={form}
        name="basic"
        onFinish={handleSubmit}
        autoComplete="off"
        layout="vertical"
        validateTrigger={["onSubmit"]}
      >
        <Form.Item
          label="Current Password"
          name="oldPassword"
          rules={[
            { required: true, message: "Please input your current password!" },
            { min: 8, message: "Current password must have at least 8 characters" },
          ]}
        >
          <PasswordEl />
        </Form.Item>

        <Form.Item
          label="New Password"
          name="newPassword"
          rules={[
            { required: true, message: "Please input your new password!" },
            { min: 8, message: "New password must have at least 8 characters" },
          ]}
        >
          <PasswordEl />
        </Form.Item>

        <Form.Item
          label="Confirm Password"
          name="reNewPassword"
          rules={[
            { required: true, message: "Please input your confirm password!" },
            { min: 8, message: "Confirm password must have at least 8 characters" },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("newPassword") === value) {
                  return Promise.resolve();
                }
                return Promise.reject("The two passwords that you entered does not match.");
              },
            }),
          ]}
        >
          <PasswordEl />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" disabled={loading}>
            Submit
          </Button>
        </Form.Item>
      </Form>
      <AlertPopup
        isOpen={alertOpen}
        duration={5000}
        type={alertType}
        message={alertMsg}
        onCloseAlert={handleCloseAlert}
      />
    </Card>
  );
};

export default ChangePasswordForm;
