import React, { useState } from "react";
import {
  RiDashboard2Line,
  RiUser3Line,
  RiImage2Fill,
  RiVipCrownLine,
  RiContactsBook2Line,
  RiQuestionnaireLine,
  RiGift2Line,
} from "react-icons/ri";
import { AiOutlineLogin, AiOutlineTransaction, AiOutlineSetting } from "react-icons/ai";
import { Layout } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { useDispatch } from "react-redux";
import { logoutUser } from "../store/actions";
import { Images } from "../assets/images";

const { Sider } = Layout;
const navBar = [
  {
    key: "/",
    label: "Dashboards",
    icon: <RiDashboard2Line size={20} color="gray" />,
    iconActive: <RiDashboard2Line size={20} color="white" />,
  },
  {
    key: "/user",
    label: "User",
    icon: <RiUser3Line size={20} color="gray" />,
    iconActive: <RiUser3Line size={20} color="white" />,
  },
  {
    key: "/image",
    label: "Image",
    icon: <RiImage2Fill size={20} color="gray" />,
    iconActive: <RiImage2Fill size={20} color="white" />,
  },
  {
    key: "/package",
    label: "Package",
    icon: <RiVipCrownLine size={20} color="gray" />,
    iconActive: <RiVipCrownLine size={20} color="white" />,
  },
  // {
  //   key: "/user-package",
  //   label: "User Package",
  //   icon: <RiVipCrownLine size={20} color="gray" />,
  //   iconActive: <RiVipCrownLine size={20} color="white" />,
  // },
  {
    key: "/contact",
    label: "Contact",
    icon: <RiContactsBook2Line size={20} color="gray" />,
    iconActive: <RiContactsBook2Line size={20} color="white" />,
  },
  {
    key: "/about-us",
    label: "About us",
    icon: <RiQuestionnaireLine size={20} color="gray" />,
    iconActive: <RiQuestionnaireLine size={20} color="white" />,
  },
  {
    key: "/transaction",
    label: "Transaction History",
    icon: <AiOutlineTransaction size={20} color="gray" />,
    iconActive: <AiOutlineTransaction size={20} color="white" />,
  },
  {
    key: "/promotion",
    label: "Promotion",
    icon: <RiGift2Line size={20} color="gray" />,
    iconActive: <RiGift2Line size={20} color="white" />,
  },
  {
    key: "/trending",
    label: "Trending",
    icon: <RiGift2Line size={20} color="gray" />,
    iconActive: <RiGift2Line size={20} color="white" />,
  },
  {
    key: "/setting",
    label: "Setting",
    icon: <AiOutlineSetting size={20} color="gray" />,
    iconActive: <AiOutlineSetting size={20} color="white" />,
  },
];

const navAccount = [
  {
    key: "/signin",
    label: "Log out",
    icon: <AiOutlineLogin size={20} color="gray" />,
  },
];

interface Props {
  collapsed: boolean;
}
const MainSidebar: React.FC<Props> = ({ collapsed }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const onLogOut = (event: string) => {
    navigate(event);
    dispatch(logoutUser());
  };
  const onMenuClick = (event: string) => {
    navigate(event);
  };
  return (
    <Sider width={285} trigger={null} collapsible collapsed={collapsed} className="px-7 bg-[#fafafa]">
      <Link to="/">
        <div className="cursor-pointer h-[100px] bg-[#fafafa] px-[3px] flex items-start py-9 ">
          <img className="h-[32px] object-contain drop-shadow-lg" src={Images.logo.default} alt="" height="48" />
        </div>
      </Link>
      <div className="text-white cursor-pointer">
        {navBar.map((item: any, index: number) => {
          const checkActive = location?.pathname === item?.key;
          return (
            <div
              onClick={() => onMenuClick(item.key)}
              key={index}
              className={`flex gap-4 mb-3 text-black  py-[10px] px-[10px] ${
                checkActive ? "bg-white rounded-lg drop-shadow-lg shadow-sm" : "bg-[#fafafa]"
              } `}
            >
              <div
                className={`h-[33px] rounded-md flex justify-center items-center w-[33px]  drop-shadow-md  ${
                  checkActive ? " bg-blue-500" : "bg-white "
                }`}
              >
                {checkActive ? item?.iconActive : item?.icon}
              </div>
              <div className={` flex items-center text-base  ${checkActive ? "font-semibold " : "font-normal "}`}>
                {item?.label}
              </div>
            </div>
          );
        })}
        <div className="px-[10px] text-[#8c8c8c] text-base font-bold uppercase">Account Page</div>
        {navAccount.map((item: any, index: number) => {
          return (
            <div
              onClick={() => onLogOut(item?.key)}
              key={index}
              className={`flex gap-4  text-black mt-2 py-[10px] px-[10px] bg-[#fafafa] `}
            >
              <div
                className={`h-[33px] rounded-md flex justify-center items-center w-[33px]  drop-shadow-md  bg-white 
                }`}
              >
                {item?.icon}
              </div>
              <div className={` flex items-center text-base  font-normal`}>{item?.label}</div>
            </div>
          );
        })}
      </div>
    </Sider>
  );
};

export default MainSidebar;
