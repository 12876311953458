export const URL_IMAGES = process.env.REACT_APP_API_ENDPOINT + "/image/";
export const URL_DELETE_IMAGE = process.env.REACT_APP_API_ENDPOINT + "/image/admin-delete";
export const URL_USERS = process.env.REACT_APP_API_ENDPOINT + "/user/";
export const URL_USERS_UPDATE_PACKAGE = process.env.REACT_APP_API_ENDPOINT + "/user/update-package";
export const URL_PACKAGES = process.env.REACT_APP_API_ENDPOINT + "/package/all-package";
export const URL_NEW_PACKAGES = process.env.REACT_APP_API_ENDPOINT + "/package/";
export const URL_UPDATE_PACKAGE = process.env.REACT_APP_API_ENDPOINT + "/package/update-package";
export const URL_DELETE_PACKAGE = process.env.REACT_APP_API_ENDPOINT + "/package/";
export const URL_USER_STATUS = process.env.REACT_APP_API_ENDPOINT + "/user/update-status";
export const URL_UPGRADE_PACKAGE = process.env.REACT_APP_API_ENDPOINT + "/package/admin-upgrade-package";
export const URL_DATA_LINECHART = process.env.REACT_APP_API_ENDPOINT + "/image/analysis-generate-image-in-month";
export const URL_ADMIN_USER = process.env.REACT_APP_API_ENDPOINT + "/admin/signin";
export const URL_DATA_BARCHART = process.env.REACT_APP_API_ENDPOINT + "/package/chart/total-user-package";
export const URL_ABOUT_US = process.env.REACT_APP_API_ENDPOINT + "/about-us";
export const URL_UPLOAD_IMAGES_ABOUT_US = process.env.REACT_APP_API_ENDPOINT + "/about-us/sign-url-image";
export const URL_CONTACTS = process.env.REACT_APP_API_ENDPOINT + "/contact";
export const URL_TRANSACTION = process.env.REACT_APP_API_ENDPOINT + "/payment/payment-history";
export const URL_PUT_STATUS_TRANSACTION = process.env.REACT_APP_API_ENDPOINT + "/payment/payment-confirmed";
export const URL_GROSS_REVENUE = process.env.REACT_APP_API_ENDPOINT + "/payment/gross-revenue";
export const URL_USER_TRANSACTION = process.env.REACT_APP_API_ENDPOINT + "/user/user-package";
export const URL_PROMOTIONS = process.env.REACT_APP_API_ENDPOINT + "/notification";
export const URL_NEW_PROMOTION = process.env.REACT_APP_API_ENDPOINT + "/notification/create-promotion";
export const URL_UPDATE_PROMOTION = process.env.REACT_APP_API_ENDPOINT + "/notification/update-promotion";

export const URL_TRENDING = process.env.REACT_APP_API_ENDPOINT + "/trending/";
export const URL_LISTHASHTAG = process.env.REACT_APP_API_ENDPOINT + "/hashtag/";

export const URL_UPGRADE_BATCH_PACKAGE = process.env.REACT_APP_API_ENDPOINT + "/package/admin-upgrade-batch-package";
export const URL_RESET_PASSWORD = process.env.REACT_APP_API_ENDPOINT + "/user/reset-password";
export const URL_CHECK_UPDATE_PASSWORD = process.env.REACT_APP_API_ENDPOINT + "/user/check-update-password";
export const URL_CHANGE_PASSWORD = process.env.REACT_APP_API_ENDPOINT + "/user/change-password-admin";
export const SETTING_IMG_GENERTE = process.env.REACT_APP_API_ENDPOINT + "/config/642e408a0692018b0281372d";
export const GET_TYPE_IMG_GENERTE = process.env.REACT_APP_API_ENDPOINT + "/config";
