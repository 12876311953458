import { Button, Form, Input, message, Modal, Upload, UploadFile, UploadProps } from "antd";
import React, { useEffect, useState } from "react";
import { saveAboutUs } from "../../../../helpers/api/func";
import { AlertPopup } from "../../../../components/common";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { formats, modules } from "./formAbout.config";
import { PlusOutlined } from "@ant-design/icons";
import { v4 as uuidv4 } from "uuid";
import { URL_UPLOAD_IMAGES_ABOUT_US } from "../../../../constants";
import { BodyReqForm, IPropsAboutUs } from "../aboutUs.type";

const FormAboutUs = (props: IPropsAboutUs) => {
  const { data, length = 1 } = props;
  const [form] = Form.useForm();
  const [alertType, setAlertType] = useState("");
  const [alertMsg, setAlertMsg] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [itemImagePreview, setItemImagePreview] = useState("");
  const [images, setImages] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const reduceFile = (data: string[]) => {
    return data?.reduce((totalFile: any[], image: string) => {
      const newFile: UploadFile = {
        uid: uuidv4(),
        name: "image.png",
        status: "done",
        url: image || "",
      };
      return [...totalFile, newFile];
    }, []);
  };
  useEffect(() => {
    if (data) {
      const newFile = reduceFile(data?.images) || [];
      setFileList(newFile);
      setImages(data?.images);
    }
  }, [data]);
  useEffect(() => {
    if (data) {
      const newData = {
        title: data?.title,
        content: data?.content,
        images: images || [],
      };
      form.setFieldsValue(newData);
    }
  }, [data, images]);

  const onResetAlert = () => {
    setAlertType("");
    setAlertMsg("");
  };
  const onSaveForm = async (bodyReq: BodyReqForm) => {
    setLoading(true);
    try {
      await saveAboutUs(bodyReq);
      setOpenAlert(true);
      setAlertType("success");
      setAlertMsg("Your Data Has Been Successfully Saved");
    } catch (error) {
      setOpenAlert(true);
      setAlertType("error");
      setAlertMsg("Failed to save data, please try again!");
    } finally {
      setLoading(false);
    }
  };
  const checkInvalidValue = (value: string) => {
    if (value === "<p><br></p>") {
      setOpenAlert(true);
      setAlertType("error");
      setAlertMsg("Content is invalid, please try again!");
      return false;
    }
    return true;
  };
  const handleSaveForm = async (values: any) => {
    if (checkInvalidValue(values?.content)) {
      const bodyReq = {
        companyCode: data?.companyCode || 0,
        title: values?.title.trim(),
        content: values?.content.trim(),
        images: images || [],
      };
      await onSaveForm(bodyReq);
    }
  };
  const handleCancelModal = () => {
    setOpenModal(false);
  };
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  const handlePreview = async (file: any) => {
    setItemImagePreview(file?.url);
    setOpenModal(true);
  };
  const handleChange: UploadProps["onChange"] = async (files) => {
    const { fileList: newFileList, file } = files;
    setLoading(true);
    if (file?.response?.data) {
      setImages([...images, file?.response?.data]);
      setLoading(false);
    }
    setFileList(newFileList);
  };

  const handleRemoveImage = (e: UploadFile) => {
    const img = images.filter((item) => item !== e.url);
    setImages(img);
  };

  return (
    <>
      <Form
        form={form}
        name="userForm"
        autoComplete="off"
        onFinish={handleSaveForm}
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 10,
        }}
        className="mt-8"
      >
        <Form.Item
          name="title"
          label="Title"
          rules={[
            {
              required: true,
              message: "Please input your Title!",
            },
            {
              pattern: /^[^ ][\w\W ]*[^ ]/,
              message: "Title is Invalid",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="content"
          label="Content"
          rules={[
            {
              required: true,
              message: "Please input your Content!",
            },
          ]}
          className="h-full"
        >
          <ReactQuill theme="snow" formats={formats} modules={modules} className="bg-white" />
        </Form.Item>
        <Form.Item
          label="Upload"
          name="images"
          rules={[
            {
              required: true,
              message: "Please input your Images!",
            },
          ]}
        >
          <Upload
            action={URL_UPLOAD_IMAGES_ABOUT_US}
            listType="picture-card"
            fileList={fileList}
            onPreview={handlePreview}
            onChange={handleChange}
            onRemove={handleRemoveImage}
            accept="image/*"
          >
            {fileList.length >= length ? null : uploadButton}
          </Upload>
        </Form.Item>
        <Modal open={openModal} title={"Preview"} footer={null} onCancel={handleCancelModal} width={900}>
          <img alt="example" className="w-full h-[550px]" src={itemImagePreview} />
        </Modal>
        <Form.Item
          wrapperCol={{
            offset: 8,
            span: 16,
          }}
        >
          <Button type="primary" htmlType="submit" disabled={loading || fileList.length < length}>
            Save
          </Button>
        </Form.Item>
      </Form>
      <AlertPopup isOpen={openAlert} type={alertType} message={alertMsg} onCloseAlert={onResetAlert} />
    </>
  );
};

export default FormAboutUs;
