import BarChart from "./barChart";
import LineChart from "./lineChart";
import { Chart, registerables } from "chart.js";
import OverViewCards from "./overViewCards";
import TransactionManagement from "../Transaction";
import UserManagement from "../UserManagement";
Chart.register(...registerables);

const Dashboard = () => {
  return (
    <div className="flex flex-col">
      <OverViewCards />
      <div className="flex gap-3 mt-4">
        <BarChart />
        <LineChart />
      </div>
      <div className="flex gap-3 mt-4">
        <div className="w-[40%] row-span-1">
          <UserManagement isInput={false} dashboard />
        </div>
        <div className="w-[60%] flex-1">
          <TransactionManagement isInput={false} dashboard />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
