import { Avatar, Button, Divider, Drawer, Modal, Space } from "antd";
import React, { useState } from "react";
import { UserType } from "../defineType";
import { UserOutlined } from "@ant-design/icons";
import { GiSmartphone } from "react-icons/gi";
import { TbAddressBook } from "react-icons/tb";
import { CgMail } from "react-icons/cg";
import moment from "moment";
import { timeConvert } from "../../../utils/helpers";
interface AppProps {
  title: string;
  isModalOpen: boolean;
  onCloseModal: () => void;
  data: UserType;
  type: string;
  packageList: any;
}
const UserModal: React.FC<AppProps> = ({ isModalOpen, onCloseModal, data, type, packageList }) => {
  const option = packageList?.find((o: any) => o?.key === data?.package)?.label || "";
  const userData = () => (
    <Space size="large" align="start">
      {data ? (
        <div className="w-[300px] h-[140px] ">
          <div className="w-full h-full text-center border">
            {data.thumbnailUrl ? (
              <Avatar size={80} src={data.thumbnailUrl} />
            ) : (
              <Avatar size={80} icon={<UserOutlined />} />
            )}
            <div className="mt-2 text-lg font-semibold">{data?.name}</div>
          </div>
          <div className="font-bold mt-4  text-[13px] opacity-60">ACCOUNT DETAILS</div>
          <div className=" mt-4 flex gap-3">
            <p className="w-[50px]">Status</p>
            <p className="font-semibold"> {data.status === 1 ? "Active" : "Inactive"}</p>
          </div>
          <div className="flex gap-3 -mt-4">
            <p className="w-[50px]">Package</p> <p className="font-semibold">{option}</p>
          </div>
          <div className="flex gap-3 -mt-4">
            <p className="w-[50px]">CreateAt</p> <p className="font-semibold">{timeConvert(data?.createdAt)}</p>
          </div>
          <div className="font-bold mt-10 opacity-60 text-[13px]">CONTACT</div>
          <div className="mt-4 flex gap-3 h-[36px]">
            <GiSmartphone size={20} />
            <p className="font-semibold">{data?.phone}</p>
          </div>
          <div className="flex gap-3 h-[36px]">
            <CgMail size={20} />
            <p className="font-semibold">{data?.email}</p>
          </div>
          <div className=" gap-3 flex h-[36px]">
            <TbAddressBook size={20} />
            <p className="font-semibold"> {data?.address}</p>
          </div>
        </div>
      ) : (
        <p>Data not found</p>
      )}
    </Space>
  );

  const loadContent = () => {
    switch (type) {
      case "view":
        return userData();

      default:
        break;
    }
  };

  return (
    <Drawer closable={false} width={320} onClose={onCloseModal} open={isModalOpen}>
      {loadContent()}
    </Drawer>
  );
};

export default UserModal;
