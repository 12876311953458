import { Select } from "antd";
import React from "react";
import { useState } from "react";
import { TYPE_PACKAGE_MAP } from "../../../constants/common.constant";

interface PackageType {
  key: string;
  label: string;
}
interface UserPackageProps {
  defaultPackage?: string;
  onSelect: (key: string) => void;
  packageList: PackageType[] | [];
}

const { Option, OptGroup } = Select;

const ACTION_LIST_MAP = [
  {
    value: 0,
    key: 0,
    label: "Package",
  },
  {
    value: 1,
    key: 1,
    label: "Status",
  },
];
const UserPackageDropdown: React.FC<UserPackageProps> = ({ defaultPackage, onSelect, packageList }) => {
  const [action, setActions] = useState();
  return (
    <>
      <Select
        placeholder="Select Action"
        style={{ width: 150 }}
        options={ACTION_LIST_MAP}
        onSelect={(e: any) => {
          setActions(e);
        }}
      />
      {action === 0 && (
        <Select style={{ width: "200px" }} onSelect={(key: string) => onSelect(key)} placeholder="Select Package">
          {TYPE_PACKAGE_MAP.map((tp: any) => (
            <OptGroup key={tp.value} label={tp.label}>
              {packageList
                .filter((plf: any) => plf.typePackage === tp.value)
                .map((pl: any) => (
                  <Option value={pl.key} key={pl.key}>
                    {pl.label}
                  </Option>
                ))}
            </OptGroup>
          ))}
        </Select>
      )}
    </>
  );
};

export default UserPackageDropdown;
