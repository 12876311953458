import { Input } from "antd";
import React, { useState } from "react";

const { Search } = Input;

interface AppProps {
  onSubmitSearch: (keyword: string) => void;
  placeholder?: string;
  size?: "large" | "middle" | "small";
}

const SearchBox: React.FC<AppProps> = ({ onSubmitSearch, placeholder, size }) => {
  const [searchValue, setSearchValue] = useState<string>("");

  const onSearch = (value: string) => {
    onSubmitSearch(value.toLowerCase().trim());
    setSearchValue(value.trim());
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  return (
    <Search
      value={searchValue}
      placeholder={placeholder || "Input search text"}
      size={size}
      enterButton
      onChange={handleChange}
      onSearch={onSearch}
    />
  );
};

export default SearchBox;
