import { Avatar, Space } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { SelectBox } from "../../../components/common";
import { UserType } from "../defineType";
import moment from "moment";
import { timeConvert } from "../../../utils/helpers";

type Option = {
  key: string;
  label: string;
};

export const columns2: any = (
  packageList: Option[],
  onSelect: (packageId: string, userId: string | undefined) => void,
) => [
  {
    title: "User",
    dataIndex: "thumbnailUrl",
    key: "thumbnailUrl",
    width: 400,
    render: (url: string, record: any) => {
      return (
        <div className="flex gap-2 items-center ">
          <Space>
            {url ? (
              <Avatar className="shadow-xl" size={40} src={url} />
            ) : (
              <Avatar className="shadow-xl" size={40} icon={<UserOutlined />} />
            )}
          </Space>
          <div>
            <p className="m-0 font-bold">{record?.name}</p>
            <p className="m-0 opacity-50 font-medium">{record?.email}</p>
          </div>
        </div>
      );
    },
  },
  {
    title: "CreateAt",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (pg: string, record: UserType) => <div>{timeConvert(pg)}</div>,
  },
  // {
  //   title: "Package",
  //   dataIndex: "package",
  //   key: "package",
  //   render: (pg: string, record: UserType) => {
  //     return (
  //       <div>
  //         <SelectBox
  //           btnSize="middle"
  //           options={packageList}
  //           defaultSelected={pg}
  //           onSelect={(key: string) => onSelect(key, record?.id)}
  //         />
  //       </div>
  //     );
  //   },
  // },
];
