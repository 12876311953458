import { DeleteOutlined, FormOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import moment from "moment";
export const columns: any = ({ onOpenModal, onDeleteTrending }: any) => [
  {
    dataIndex: "hashtagList",
    key: "hashtagList",
    title: "Hashtags",
    align: "left",
    render: (hashtags: any) => {
      const listItem = () => {
        return (
          <>
            {hashtags?.map((value: any, index: number) => {
              const marginLeft = index !== 0 ? "5px" : "0";
              const limitedDisplay = index < 5; // less than 6 elements
              return (
                <div key={value + index}>
                  {limitedDisplay && (
                    <div
                      className="border-solid border border-[#f0f0f0] bg-[#f5f5f5] rounded-md"
                      style={{ marginLeft }}
                    >
                      <span className="mx-[5px]">{value}</span>
                    </div>
                  )}
                </div>
              );
            })}
          </>
        );
      };

      const textTooltip = (
        <div className="flex flex-wrap">
          {hashtags.map((value: any) => (
            <div
              className="border-solid border border-[#f0f0f0] bg-[#f5f5f5]
             rounded-md text-[#252525] p-[0px 3px] m-[2.5px 0px 2.5px 5px] my-[2.5px] ml-[5px] px-[5px]"
            >
              {value}
            </div>
          ))}
        </div>
      );

      return (
        <div className="flex">
          {listItem()}
          {hashtags?.length > 5 && (
            <Tooltip placement="top" title={textTooltip} color={"white"}>
              <div className="ml-2">...</div>
            </Tooltip>
          )}
        </div>
      );
    },
  },
  {
    dataIndex: "startDate",
    key: "startDate",
    title: "Created At",
    align: "left",
    render: (content: any) => {
      const newDate: any = moment.utc(content).format("YYYY/MM/DD");
      return <div className="line-clamp-3">{newDate}</div>;
    },
  },
  {
    dataIndex: "endDate",
    key: "endDate",
    title: "Expired At",
    align: "left",
    render: (content: any) => {
      const newDate: any = moment.utc(content).format("YYYY/MM/DD");
      return <div className="line-clamp-3">{newDate}</div>;
    },
  },
  {
    title: "Action",
    key: "action",
    align: "center",
    render: (record: any) => {
      return (
        <div className="flex space-x-3 justify-center">
          <FormOutlined style={{ cursor: "pointer" }} onClick={() => onOpenModal(record)} />
          <DeleteOutlined style={{ cursor: "pointer" }} onClick={() => onDeleteTrending(record)} />
        </div>
      );
    },
  },
];
