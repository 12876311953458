import { Space } from "antd";
import moment from "moment";
import { timeConvert } from "../../../utils/helpers";
import { ImageProps } from "../defineType";
import { DeleteOutlined } from "@ant-design/icons";

export const columns: any = ({ onOpenModal, onDeleteImage }: any) => [
  {
    title: "Image",
    key: "imageUrl",
    align: "center",
    render: (record: ImageProps) => {
      return (
        <Space size="middle">
          <a onClick={() => onOpenModal(record)}>
            <img src={record.imageUrl || ""} className="w-[100px] h-[100px] shadow-xl" alt="style" />
          </a>
        </Space>
      );
    },
  },
  {
    title: "Prompt",
    dataIndex: "prompt",
    key: "prompt",
    width: 400,
    render: (prompt: string) => {
      return <div className="line-clamp-3">{prompt || ""}</div>;
    },
  },
  {
    title: "Number of likes",
    dataIndex: "numberOfLike",
    key: "numberOfLike",
    sorter: true,
    align: "center",
    render: (numberOfLike: number) => {
      return <div>{numberOfLike || 0}</div>;
    },
  },
  {
    title: "Status",
    dataIndex: "isPublic",
    key: "isPublic",
    align: "center",
    sorter: true,
    render: (isPublic: boolean) => <div>{isPublic ? "Public" : "Private"}</div>,
  },
  {
    title: "Date created",
    dataIndex: "createdAt",
    key: "createdAt",
    align: "center",
    sorter: true,
    render: (createdAt: string) => <div>{timeConvert(createdAt)}</div>,
  },
  {
    title: "Action",
    key: "action",
    align: "center",
    render: (record: any) => {
      return (
        <div className="flex space-x-3">
          <DeleteOutlined onClick={() => onDeleteImage(record)} />
        </div>
      );
    },
  },
];
