import { Button, Card, Form, Modal, Space } from "antd";
import React, { useState, useEffect, useMemo, useRef } from "react";
import { AlertPopup, SearchBox, SelectBox } from "../../../components/common";
import { createTrending, deleteTrending, getAllTrending, updateTrending } from "../../../helpers/api/func";
import TrendingModal from "./modal";
import TrendingTable from "./table";
import { columns } from "./trending.config";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import moment from "moment";

const initModalAttrs = {
  isOpen: false,
  title: "",
  type: "add",
};
const SEARCH_FIELD_OPTIONS = [
  { key: "hashtag", label: "Hashtag", default: true },
  { key: "createdAt", label: "CreateAt" },
];

const INIT_PAGINATION_VALUES = {
  current: 1,
  pageSize: 10,
  total: 12,
};

const defaultParams = { limit: 10, page: 1, sort: "hashtag", type: 1, hashtag: "", current: 1, pageSize: 10 };

const Trending: React.FC = () => {
  const [modalAttrs, setModalAttrs] = useState(initModalAttrs);
  const [dataSource, setDataSource] = useState<any>([]);
  const [trendingId, setTrendingId] = useState("");
  const [param, setParam] = useState<any>(defaultParams);
  const [loading, setLoading] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [paginations, setPagination] = useState<any>(INIT_PAGINATION_VALUES);

  const alertType = useRef<string>("");
  const alertMsg = useRef<string>("");
  const [form] = Form.useForm();

  const handleCloseModal = () => {
    setModalAttrs(initModalAttrs);
    form.resetFields();
  };

  const fetchTrending = async (param: any) => {
    setLoading(true);
    try {
      const result = await getAllTrending(param);
      const data = result?.data?.listTagTrending;
      if (data) {
        const add = data?.map((value: any) => {
          return {
            key: value._id,
            hashtagList: value.hashtagList,
            startDate: value.startDate,
            endDate: value.endDate,
          };
        });
        setDataSource(add);
      }

      if (result.data.count > 10) {
        setPagination({ current: param.page, pageSize: param.limit, total: result.data.count });
      } else {
        setPagination({ current: 1, pageSize: 10, total: 0 });
      }
    } catch (error) {
      alertType.current = "error";
      alertMsg.current = "Failed to load data, please try again";
      setAlertOpen(true);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchTrending(param);
  }, [param]);

  const handleOpenModalAdd = () => {
    setModalAttrs({ title: "Add new Trending", isOpen: true, type: "add" });
  };

  const fechAdd = async (value?: any) => {
    try {
      const result = await createTrending(value);
      if (result?.status && result?.status === 200) {
        setAlertOpen(true);
        alertType.current = "success";
        alertMsg.current = "Trending was created";
        fetchTrending(param);
      }
    } catch (error) {
      setAlertOpen(true);
      alertType.current = "error";
      alertMsg.current = "Failed to create new trending, please try again";
    } finally {
      handleCloseModal();
    }
  };

  const handleEditToForm = (record: any) => {
    setModalAttrs({ title: "Edit  new Trending", isOpen: true, type: "edit" });
    setTrendingId(record.key);
    const endDate = moment.utc(record.endDate).format("YYYY/MM/DD");
    form.setFieldsValue({ ...record, picker: [moment(record.startDate), moment(endDate)] });
  };

  const fechEdit = async (valueObject: any) => {
    const { hashtagList, startDate, endDate } = valueObject;
    const value = {
      hashtagList,
      startDate,
      endDate,
    };
    try {
      const id = trendingId;
      const result = await updateTrending(id, value);
      if (result?.status && result?.status === 200) {
        fetchTrending(param);
        setAlertOpen(true);
        alertType.current = "success";
        alertMsg.current = "Trending was updated";
      }
    } catch (error) {
      setAlertOpen(true);
      alertType.current = "error";
      alertMsg.current = "Failed to update trending, please try again";
    } finally {
      handleCloseModal();
    }
  };

  const handleDeleteTrending = async (record: any) => {
    Modal.confirm({
      title: `Delete Trending`,
      icon: <ExclamationCircleOutlined />,
      content: `Do you want to delete trending ?`,
      okText: "Confirm",
      cancelText: "Cancel",
      async onOk() {
        try {
          await deleteTrending(record.key || "");
          fetchTrending(param);
          setAlertOpen(true);
          alertType.current = "success";
          alertMsg.current = "Trending was deleted";
        } catch (err) {
          setAlertOpen(true);
          alertType.current = "error";
          alertMsg.current = "Failed to update , please try again";
        }
      },
    });
  };

  const handleSubmitSearch = (value: any) => {
    setParam({ ...param, hashtag: value });
  };
  const handleSelect = (value: string) => {
    setParam({
      ...param,
      sort: value,
    });
  };

  const handleCloseAlert = () => {
    alertType.current = "";
    alertMsg.current = "";
    setAlertOpen(false);
  };

  const handleTableChange = async (pagination: any, sorter: any, extra: any) => {
    setParam({
      ...param,
      limit: pagination.pageSize,
      page: pagination.current,
    });
    try {
      setPagination({ ...paginations, current: pagination?.current, pageSize: pagination?.pageSize });
    } catch (error: any) {
      alertType.current = "error";
      alertMsg.current = "Failed to load data, please try again";
      setAlertOpen(true);
    } finally {
      setLoading(false);
    }
  };
  const handleSubmitForm = (values: any) => {
    switch (modalAttrs.type) {
      case "add":
        fechAdd(values);
        break;
      case "edit":
        fechEdit(values);
        break;

      default:
        handleCloseModal();
        break;
    }
  };

  return (
    <div className="relative">
      <Card>
        <Space direction="horizontal" size="middle" className="mb-4 flex justify-between">
          <h1 className="font-bold text-xl">Trending Table</h1>
          <div className="flex gap-4 ">
            <Space direction="horizontal">
              <SelectBox
                options={SEARCH_FIELD_OPTIONS}
                defaultSelected={"hashtag"}
                onSelect={handleSelect}
                label="Filter by:"
              />
              <SearchBox onSubmitSearch={handleSubmitSearch} size="large" />
            </Space>
            <Button type="primary" size="large" onClick={handleOpenModalAdd}>
              New Trending
            </Button>
          </div>
        </Space>
        <TrendingTable
          dataSource={dataSource || []}
          columnsTable={columns}
          onDeleteTrending={handleDeleteTrending}
          onOpenModal={handleEditToForm}
          onTableChange={handleTableChange}
          loading={loading}
          pag={paginations}
        />
        <TrendingModal
          onCloseModal={handleCloseModal}
          modalAttrs={modalAttrs}
          getObjectModal={handleSubmitForm}
          form={form}
        />
        <AlertPopup
          isOpen={alertOpen}
          type={alertType.current}
          message={alertMsg.current}
          onCloseAlert={handleCloseAlert}
        />
      </Card>
    </div>
  );
};

export default Trending;
