import { Table } from "antd";
import React, { useState, useMemo, useEffect, memo } from "react";

const showTotal = (total: number, range: any) => {
  return `${range.join("-")} of ${total} items`;
};

const initPagination = {
  current: 1,
  pageSize: 10,
  total: 0,
  showSizeChanger: true,
  pageSizeOptions: ["10", "20", "30"],
  showTotal: showTotal,
};

type pagination = { current: number; pageSize: number; total: number };

interface Props {
  dataSource?: any;
  columnsTable?: any;
  onDeleteTrending?: any;
  onOpenModal?: any;
  loading?: boolean;
  pag?: any;
  onTableChange?: any;
}
const TrendingTable: React.FC<Props> = (props) => {
  const { dataSource, columnsTable, onOpenModal, onDeleteTrending, loading, pag, onTableChange } = props;

  const [pagination, setPagination] = useState<pagination>(initPagination);
  const columnsTables = useMemo(() => columnsTable({ onOpenModal, onDeleteTrending }), []);

  useEffect(() => {
    setPagination({ ...pagination, ...pag });
  }, [pag]);

  return (
    <div>
      <Table
        columns={columnsTables}
        dataSource={dataSource}
        loading={loading}
        onChange={(pagination, sorter, extra) => onTableChange(pagination, sorter, extra)}
        pagination={pagination}
      />
    </div>
  );
};

export default memo(TrendingTable);
