import { Card } from "antd";
import { useState } from "react";
import SettingTable from "./settingTable";
import { AlertPopup } from "../../../components/common";
import { alertDefault } from "./setting.config";

const Setting = () => {
  const [alert, setAlert] = useState(alertDefault);

  const handleAlert = (value: any) => {
    setAlert(value);
  };

  const handleCloseAlert = () => {
    setAlert(alertDefault);
  };

  return (
    <div className="relative">
      <Card>
        <SettingTable handleAlert={handleAlert} />
      </Card>
      <AlertPopup
        duration={5000}
        isOpen={alert.isOpen}
        type={alert.type}
        message={alert.msg}
        onCloseAlert={handleCloseAlert}
      />
    </div>
  );
};

export default Setting;
