import { Avatar, Space } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { UserType } from "../defineType";
import moment from "moment";
import { PAYMENT_METHOD_MAP, PAYMENT_STATUS_MAP } from "../../../constants/transaction";
import { SelectBox } from "../../../components/common";

export const columns2: any = (onSelect: any) => [
  {
    title: "User",
    dataIndex: "userId",
    key: "userId",
    render: (user: any) => {
      return (
        <div className="flex gap-2 items-center ">
          <Space>
            {user ? (
              <Avatar className="shadow-xl" size={40} src={user?.thumbnailUrl || ""} />
            ) : (
              <Avatar className="shadow-xl" size={40} icon={<UserOutlined />} />
            )}
          </Space>
          <div>
            <p className="m-0 font-bold">{user?.name}</p>
            <p className="m-0 opacity-50 font-medium">{user?.email}</p>
          </div>
        </div>
      );
    },
  },

  {
    title: "Package",
    dataIndex: "packageId",
    key: "packageId",
    render: (pg: any) => <div>{pg?.title}</div>,
  },

  {
    title: "Payment Methods",
    dataIndex: "payMethod",
    key: "payMethod",
    render: (payMethod: number) => <div>{PAYMENT_METHOD_MAP.find((e) => e.value === payMethod)?.label}</div>,
  },
];
