import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Button, Checkbox, Form, Input } from "antd";
import { AlertPopup } from "../../../components/common";
import { resetPassword } from "../../../helpers/api/func";

interface FormProps {
  email: string;
}

interface Props {
  setIsSendMail: React.Dispatch<React.SetStateAction<boolean>>;
}

const FormSendMailVerify: React.FC<Props> = ({ setIsSendMail }) => {
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<string>("");
  const [alertMsg, setAlertMsg] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const handleSubmit = async (values: FormProps) => {
    setLoading(true);
    try {
      await resetPassword(values);
      setAlertOpen(true);
      setIsSendMail(true);
      navigate(`/forgot-password/${values.email}`);
    } catch (error: any) {
      setAlertOpen(true);
      setAlertType("error");
      setAlertMsg(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseAlert = () => {
    setAlertOpen(false);
    setAlertType("");
    setAlertMsg("");
  };

  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "Your ${name} is not a valid email!",
    },
  };
  return (
    <>
      <Form
        form={form}
        name="basic"
        onFinish={handleSubmit}
        autoComplete="off"
        layout="vertical"
        validateMessages={validateMessages}
        validateTrigger={["onSubmit"]}
      >
        <Form.Item
          label="Email"
          name="email"
          rules={[{ type: "email" }, { required: true, message: "Please input your email!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" disabled={loading}>
            Submit
          </Button>
        </Form.Item>
      </Form>
      <AlertPopup
        isOpen={alertOpen}
        duration={5000}
        type={alertType}
        message={alertMsg}
        onCloseAlert={handleCloseAlert}
      />
    </>
  );
};

export default FormSendMailVerify;
