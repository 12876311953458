import { Card } from "antd";
import { ChartData } from "chart.js";
import React, { useLayoutEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { AlertPopup } from "../../../components/common";
import { getDataLineChart } from "../../../helpers/api/func";
import useQuery from "../../../hooks/useQuery";
import { ChartItemProps, optionLineChart, pluginsLineChart } from "./DashBoards.config";

const LineChart: React.FC = () => {
  const [dataChart, setDataChart] = useState<ChartData<"line">>();
  const { data = [], loading, openAlert, setOpenAlert } = useQuery(getDataLineChart);

  useLayoutEffect(() => {
    setDataChart({
      labels: data.length > 0 ? data.map((item: ChartItemProps) => item.label || "") : [],
      datasets: [
        {
          label: "Number of generated images",
          data: data.length > 0 ? data.map((item: ChartItemProps) => item.value || "") : [],
          borderColor: "rgb(0, 143, 251)",
          cubicInterpolationMode: "monotone",
          backgroundColor: "rgb(0, 143, 251, 0.5)",
          borderWidth: 4,
        },
      ],
    });
  }, [loading]);

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  return (
    <>
      <Card className="rounded-xl text-center  flex-1">
        <div className="text-center text-[16px] font-semibold uppercase mb-6">
          NUMBER OF GENERATED IMAGES PER DAY IN CURRENT MONTH
        </div>
        {loading ? (
          <p>Loading...</p>
        ) : (
          <>
            {dataChart ? (
              <Line className="line-chart" data={dataChart} options={optionLineChart} plugins={pluginsLineChart} />
            ) : (
              <p>Data not found</p>
            )}
          </>
        )}
      </Card>
      <AlertPopup isOpen={openAlert} type={"error"} message={"Cannot get data"} onCloseAlert={handleCloseAlert} />
    </>
  );
};

export default LineChart;
