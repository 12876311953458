import { useState, useEffect, useCallback } from "react";

export default function useQuery(api: any) {
  const [data, setData] = useState<any>();
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);

  const checkStatusApi = (res: any) => {
    if (res?.status === 200 && res?.message === "ok") return true;
    return false;
  };

  const runQuery = useCallback(async () => {
    setLoading(true);
    try {
      const res = await api();
      if (checkStatusApi(res)) {
        setData(res?.data);
      }
    } catch (error: any) {
      setError("Failed to load data, please try again");
      setOpenAlert(true);
    } finally {
      setLoading(false);
    }
  }, []);
  useEffect(() => {
    runQuery();
  }, [runQuery]);

  return { data, loading, error, openAlert, setOpenAlert, setLoading, setError };
}
