import { FormOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Select, Space, Switch } from "antd";
import { AiOutlineEye } from "react-icons/ai";
import { TYPE_PACKAGE_MAP } from "../../../constants/common.constant";
import { timeConvert } from "../../../utils/helpers";
import { UserType } from "../defineType";

type Option = {
  key: string;
  label: string;
};
const { Option, OptGroup } = Select;

export const columns: any = (
  onSwitch: any,
  onOpenModal: (record: UserType) => void,
  packageList: any,
  onSelect: any,
  onOpenEditModal: (record: UserType) => void,
) => [
  {
    title: "User",
    dataIndex: "thumbnailUrl",
    key: "thumbnailUrl",
    render: (url: string, record: any) => {
      return (
        <div className="flex gap-2 items-center ">
          <Space>
            {url ? (
              <Avatar className="shadow-xl" size={40} src={url} />
            ) : (
              <Avatar className="shadow-xl" size={40} icon={<UserOutlined />} />
            )}
          </Space>
          <div>
            <p className="m-0 font-bold">{record?.name}</p>
            <p className="m-0 opacity-50 font-medium">{record?.email}</p>
          </div>
        </div>
      );
    },
  },
  {
    title: "Package",
    dataIndex: "package",
    key: "package",
    sorter: true,
    render: (pg: any, record: any) => {
      return (
        <div>
          <Select value={pg} style={{ width: "200px" }} onSelect={(key: string) => onSelect(key, record?.id)}>
            {TYPE_PACKAGE_MAP.map((tp: any) => (
              <OptGroup key={tp.value} label={tp.label}>
                {packageList
                  .filter((plf: any) => plf.typePackage === tp.value)
                  .map((pl: any) => (
                    <Option value={pl.key} key={pl.key}>
                      {pl.label}
                    </Option>
                  ))}
              </OptGroup>
            ))}
          </Select>
        </div>
      );
    },
  },
  {
    title: "Start Days",
    dataIndex: "startDays",
    key: "startDays",
    sorter: true,
    render: (pg: string, record: UserType) => <div>{timeConvert(pg)}</div>,
  },
  {
    title: "Expried Days",
    dataIndex: "expPackageDays",
    key: "expPackageDays",
    sorter: true,
    render: (pg: string, record: UserType) => <div>{timeConvert(pg)}</div>,
  },
  {
    title: "No. of generated/day",
    dataIndex: "maxGenPerDay",
    key: "maxGenPerDay",
  },
  {
    title: "No. of edit/day",
    dataIndex: "maxEditPerDay",
    key: "maxEditPerDay",
  },
  {
    title: "Early Adopter",
    dataIndex: "isEarlyAdopter",
    key: "isEarlyAdopter",
    sorter: true,
    render: (status: string, record: UserType, index: number) => {
      return (
        <Switch
          checked={status ? true : false}
          onChange={(checked) => onSwitch("isEarlyAdopter", checked, record, index)}
          checkedChildren="Active"
          unCheckedChildren="Inactive"
        />
      );
    },
  },

  {
    title: "Status",
    dataIndex: "status",
    sorter: true,
    render: (status: number, record: UserType, index: number) => (
      <>
        <Switch
          checked={status === 1 ? true : false}
          onChange={(checked) => onSwitch("status", checked, record, index)}
          checkedChildren="Active"
          unCheckedChildren="Inactive"
        />
      </>
    ),
  },
  {
    key: "action",
    align: "end",
    render: (record: UserType) => (
      <Space size="middle">
        <FormOutlined onClick={() => onOpenEditModal(record)} />
        <div
          className="bg-blue-500  w-8 h-8 rounded-lg text-center leading-9 cursor-pointer hover:opacity-50 active:bg-blue-800"
          onClick={() => onOpenModal(record)}
        >
          <AiOutlineEye color="white" />
        </div>
      </Space>
    ),
  },
];

export const initModalData = {
  userPackage: {
    startDays: "",
    expiredDays: "",
    balanceEdit: 0,
    balanceGen: 0,
    maxGenPerDay: 0,
    maxEditPerDay: 0,
    createdAt: "",
    updatedAt: "",
  },
};

export const initModalAttrs = {
  isOpen: false,
  title: "",
  type: "add",
};
