import React, { FC, useEffect, useRef, useState } from "react";
import { MdPeopleOutline } from "react-icons/md";
import { RiShoppingCart2Line } from "react-icons/ri";
import { RiQrScan2Line } from "react-icons/ri";
import { AlertPopup } from "../../../components/common";
import { getGrossRevenue } from "../../../helpers/api/func";

interface IData {
  label: string;
  value: number;
  icon?: React.ReactNode;
}

const ICONS = [
  <RiShoppingCart2Line size={30} color="white" />,
  <RiQrScan2Line size={30} color="white" />,
  <MdPeopleOutline size={30} color="white" />,
];

const OverViewCards: FC = () => {
  const [data, setData] = useState<IData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const alertType = useRef<string>("");
  const alertMsg = useRef<string>("");

  const getGrossRevenueData = async () => {
    setLoading(true);
    try {
      const response = await getGrossRevenue();
      setData(
        [
          {
            label: "Gross revenue previous month",
            value: response.data.grossRevenuePrevMonth,
          },
          {
            label: "Gross revenue current month",
            value: response.data.grossRevenueInMonth,
          },
          {
            label: "Gross revenue this year",
            value: response.data.grossRevenueInYear,
          },
        ].map((data: IData, index: number) => {
          return { ...data, icon: ICONS[index] };
        }),
      );
    } catch (error) {
      alertType.current = "error";
      alertMsg.current = "Failed to load data, please try again";
      setAlertOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseAlert = () => {
    onResetAlert();
  };

  const onResetAlert = () => {
    alertType.current = "";
    alertMsg.current = "";
    setAlertOpen(false);
  };

  useEffect(() => {
    getGrossRevenueData();
  }, []);

  return (
    <div className="flex justify-between mb-4">
      {data?.map((data: IData, index: number) => {
        return (
          <div
            key={index}
            className="flex shadow-lg items-center justify-between bg-white w-[32%] rounded-2xl py-4 px-6 gap-2"
          >
            <div>
              <p className="m-0 text-base font-medium text-gray-500">{data.label}</p>
              <h1 className="text-[30px] font-bold m-0 p-0">
                {Math.floor(data.value).toLocaleString("vi", {
                  style: "currency",
                  currency: "VND",
                })}
              </h1>
            </div>
            <div className="flex items-center bg-blue-500 p-2 rounded-md">{data.icon}</div>
          </div>
        );
      })}
      <AlertPopup
        isOpen={alertOpen}
        type={alertType.current}
        message={alertMsg.current}
        onCloseAlert={handleCloseAlert}
      />
    </div>
  );
};

export default OverViewCards;
