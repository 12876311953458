import React, { useEffect, useState } from "react";
import { Modal, Form, Input, DatePicker, Switch } from "antd";
import { initModalData, ModalType, PromotionType } from "./promotion.config";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";

interface PackageProps {
  modalAttrs: ModalType;
  onCloseModal: () => void;
  form?: any;
  onSubmitForm: (values: PromotionType) => void;
}

const dateFormat = "YYYY/MM/DD";
const PromotionModal: React.FC<PackageProps> = ({ modalAttrs, onCloseModal, form, onSubmitForm }) => {
  const [confirmLoading, setConfirmLoading] = useState(false);

  useEffect(() => {
    if (!modalAttrs.isOpen) {
      setConfirmLoading(false);
    }
  }, [modalAttrs.isOpen]);

  return (
    <Modal
      title={modalAttrs.title}
      open={modalAttrs.isOpen}
      confirmLoading={confirmLoading}
      onCancel={onCloseModal}
      onOk={() => {
        form.validateFields().then((values: PromotionType) => {
          setConfirmLoading(true);
          onSubmitForm(values);
        });
      }}
    >
      <Form
        form={form}
        name="modifyPackage"
        layout="vertical"
        initialValues={initModalData}
        onFinish={onSubmitForm}
        autoComplete="off"
      >
        <Form.Item label="Title" name="title" rules={[{ required: true, message: "Title is required" }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Content" name="content" rules={[{ required: true, message: "Content is required" }]}>
          <TextArea />
        </Form.Item>
        <Form.Item label="Expired At" name="expiredAt" rules={[{ required: true, message: "Expired At is required" }]}>
          <DatePicker
            style={{ borderRadius: "5px" }}
            showTime
            disabledDate={(current) => {
              const today = moment().format(dateFormat);
              return current && current < moment(today, dateFormat);
            }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default PromotionModal;
