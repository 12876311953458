import {
  GET_TYPE_IMG_GENERTE,
  SETTING_IMG_GENERTE,
  URL_CHANGE_PASSWORD,
  URL_DELETE_IMAGE,
  URL_GROSS_REVENUE,
  URL_USERS_UPDATE_PACKAGE,
} from "./../../constants/url";
import {
  URL_IMAGES,
  URL_PACKAGES,
  URL_UPDATE_PACKAGE,
  URL_USERS,
  URL_UPGRADE_PACKAGE,
  URL_USER_STATUS,
  URL_DATA_LINECHART,
  URL_ADMIN_USER,
  URL_DATA_BARCHART,
  URL_ABOUT_US,
  URL_UPLOAD_IMAGES_ABOUT_US,
  URL_CONTACTS,
  URL_TRANSACTION,
  URL_PUT_STATUS_TRANSACTION,
  URL_USER_TRANSACTION,
  URL_NEW_PACKAGES,
  URL_DELETE_PACKAGE,
  URL_PROMOTIONS,
  URL_NEW_PROMOTION,
  URL_UPDATE_PROMOTION,
  URL_TRENDING,
  URL_LISTHASHTAG,
  URL_UPGRADE_BATCH_PACKAGE,
  URL_RESET_PASSWORD,
  URL_CHECK_UPDATE_PASSWORD,
} from "../../constants";
import { APIClient } from "./client";

const api = new APIClient();

// Image API
export const getAllImages = (params?: object) => api.get(URL_IMAGES, params);
export const deleteImages = (id: any) => api.delete(`${URL_DELETE_IMAGE}/${id}`, {});
// User API
export const getAllUsers = (params: object) => api.get(URL_USERS, params);
export const updateUserStatus = (params: object) => api.update(URL_USER_STATUS, params);
// Package API
export const getAllPackages = () => api.get(URL_PACKAGES, {});
export const deletePackages = (id: any) => api.delete(`${URL_DELETE_PACKAGE}/${id}`, {});
export const createPackage = (params: object) => api.create(URL_NEW_PACKAGES, params);
export const updatePackage = (id: string, params: object) => api.update(`${URL_UPDATE_PACKAGE}/${id}`, params);
export const upgradePackage = (params: object) => api.update(URL_UPGRADE_PACKAGE, params);
export const upgradeBatchPackage = (params: object) => api.update(URL_UPGRADE_BATCH_PACKAGE, params);

// Get Data DashBoards API
export const getGrossRevenue = () => api.get(URL_GROSS_REVENUE, {});
export const getDataLineChart = () => api.get(URL_DATA_LINECHART, {});
export const getDataBarChart = () => api.get(URL_DATA_BARCHART, {});
// Admin API
export const adminSignin = (params: object) => api.create(URL_ADMIN_USER, params);
// About us API
export const saveAboutUs = (params: object) => api.create(URL_ABOUT_US, params);
export const getAboutUs = () => api.get(URL_ABOUT_US, {});
export const updaloadImagesAboutUs = (params: any) => api.create(URL_UPLOAD_IMAGES_ABOUT_US, params);
// Contact API
export const getAllContacts = (params?: object) => api.get(URL_CONTACTS, params);
// Transaction
export const getAllTransaction = (params?: any) => api.get(URL_TRANSACTION, params);
export const putStatusTransaction = (params: any) => {
  return api.update(`${URL_PUT_STATUS_TRANSACTION}/${params?.record?.id}`, params?.body);
};
// User Package
export const getUserPackage = (params?: any) => {
  return api.get(`${URL_USER_TRANSACTION}`, params);
};
export const updatePackageUser = (id: string, params: object) =>
  api.update(`${URL_USERS_UPDATE_PACKAGE}/${id}`, params);
// Promotion API
export const getAllPromotions = (params: object) => api.get(URL_PROMOTIONS, params);
export const deletePromotion = (id: string) => api.delete(`${URL_PROMOTIONS}/${id}`, {});
export const createPromotion = (params: object) => api.create(URL_NEW_PROMOTION, params);
export const updatePromotion = (id: string, params: object) => api.update(`${URL_UPDATE_PROMOTION}/${id}`, params);

// Trending API
export const getAllTrending = (params: object) => api.get(URL_TRENDING, params);
export const createTrending = (params: object) => api.create(URL_TRENDING, params);
export const deleteTrending = (id: string) => api.delete(`${URL_TRENDING}/${id}`, {});
export const updateTrending = (id: string, params: object) => api.update(`${URL_TRENDING}/${id}`, params);
export const getAllHashtag = () => api.get(URL_LISTHASHTAG, {});

// Reset Password API
export const resetPassword = (params: object) => api.create(URL_RESET_PASSWORD, params);
export const checkAndUpdatePassword = (email: string, params: object) =>
  api.create(`${URL_CHECK_UPDATE_PASSWORD}/${email}`, params);
// Change Password API
export const changePassword = (params: object) => api.create(URL_CHANGE_PASSWORD, params);
export const settingImgGenerator = (params: object) => api.update(SETTING_IMG_GENERTE, params);
export const getTypeImgGenerator = (params?: object) => api.get(GET_TYPE_IMG_GENERTE, params);
