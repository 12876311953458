export const initUserData = {
  key: "",
  email: "",
  name: "",
  status: 0,
  thumbnailUrl: "",
  prompt: "",
};

export const LEVEL_PACKAGE = {
  FREE: 0,
  CUSTOM: 98,
  ADMIN: 99,
};
export const TYPE_PACKAGE = {
  SALE: 0,
  CUSTOM: 1,
};

export const TYPE_PACKAGE_MAP = [
  { value: 0, label: "Sale" },
  { value: 1, label: "Custom" },
];
