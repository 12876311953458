import React from "react";

// Dashboard

// Authentication Page
import SignIn from "../pages/auth/SignIn";
import Dashboard from "../pages/sidebar/DashBoards";
import ManageImages from "../pages/sidebar/ImageManagement/image";
import UserManagement from "../pages/sidebar/UserManagement/user";
import PackageManagement from "../pages/sidebar/PackageManagement";
import AboutusManagement from "../pages/sidebar/AboutUsManagement";
import ContactManagement from "../pages/sidebar/ContactManagement";
import TransactionManagement from "../pages/sidebar/Transaction";
import PromotionManagement from "../pages/sidebar/PromotionManagement";
import Trending from "../pages/sidebar/TrendingManagement";
import ForgotPassword from "../pages/auth/ForgotPassword";
import ChangePasswordForm from "../pages/auth/ChangePassword";
import Setting from "../pages/sidebar/SettingManagement/setting";

const authProtectedRoutes = [
  { path: "/dashboard", element: <Dashboard /> },
  {
    path: "/",
    element: <Dashboard />,
  },
  { path: "/user", element: <UserManagement /> },
  { path: "/image", element: <ManageImages /> },
  { path: "/package", element: <PackageManagement /> },
  { path: "/about-us", element: <AboutusManagement /> },
  { path: "/contact", element: <ContactManagement /> },
  { path: "/transaction", element: <TransactionManagement /> },
  { path: "/promotion", element: <PromotionManagement /> },
  { path: "/trending", element: <Trending /> },
  { path: "/setting", element: <Setting /> },
  { path: "/change-password", element: <ChangePasswordForm /> },
];

const publicRoutes = [
  { path: "/signin", element: <SignIn /> },
  { path: "/forgot-password", element: <ForgotPassword /> },
  { path: "/forgot-password/:email", element: <ForgotPassword /> },
];

export { authProtectedRoutes, publicRoutes };
