import { Button, Dropdown, Menu, Space } from "antd";
import type { MenuProps } from "antd";
import React, { useEffect, useState } from "react";

type Option = {
  key: string | number;
  label: string;
  disabled?: boolean;
};

interface AppProps {
  options: Option[];
  label?: string;
  placement?: "bottom" | "topLeft" | "topCenter" | "topRight" | "bottomLeft" | "bottomCenter" | "bottomRight" | "top";
  onSelect: (value: string) => void;
  defaultSelected?: string;
  btnSize?: "large" | "middle" | "small";
}

const SelectBox: React.FC<AppProps> = ({ options, label, placement, onSelect, defaultSelected, btnSize }) => {
  const [optionSelected, setoptionSelected] = useState("");
  const [optionList, setOptionList] = useState<Option[]>([]);

  const onClick: MenuProps["onClick"] = ({ key }) => {
    onSelect(key);
    const option = options.find((o) => o.key === key)?.label || "";
    setoptionSelected(option);
    const arr = [...options].map((op) => {
      return { ...op, disabled: op.key === key ? true : false };
    });
    setOptionList(arr);
  };

  useEffect(() => {
    if (defaultSelected) {
      const arr = options.map((op) => {
        return { ...op, disabled: op.key === defaultSelected ? true : false };
      });
      setOptionList(arr);
      const option = options.find((o) => o.key === defaultSelected)?.label || "";
      setoptionSelected(option);
    }
  }, [options, defaultSelected]);

  return (
    <Space direction="horizontal" size="middle" style={{ display: "flex" }}>
      {label && <label>{label}</label>}
      <Dropdown
        menu={{ items: optionList, selectable: true, defaultSelectedKeys: [defaultSelected || ""], onClick: onClick }}
        placement={placement || "bottom"}
        overlayStyle={{ textAlign: "center" }}
      >
        <Button size={btnSize || "large"}>
          <span>{optionSelected}</span>
        </Button>
      </Dropdown>
    </Space>
  );
};

export default SelectBox;
