import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { Table } from "antd";
import { PromotionType } from "./promotion.config";

const showTotal = (total: number, range: any) => {
  return `${range.join("-")} of ${total} items`;
};

const initPagination = {
  current: 1,
  pageSize: 10,
  total: 0,
  showSizeChanger: true,
  pageSizeOptions: ["10", "20", "30"],
  showTotal: showTotal,
};

type pagination = { current: number; pageSize: number; total: number };

interface IContactTable {
  data: any;
  loading: boolean;
  columnsTable: any;
  pag?: pagination;
  onTableChange: (pagination: object, sorter: any, extra: object) => void;
  onOpenModal: (record: PromotionType) => void;
  onDeletePromotion: (id: any) => void;
}

const ContactTable: React.FC<IContactTable> = ({
  data,
  loading,
  columnsTable,
  pag,
  onTableChange,
  onOpenModal,
  onDeletePromotion,
}) => {
  const [pagination, setPagination] = useState<pagination>(initPagination);
  const columnsTables = useMemo(() => columnsTable({ onOpenModal, onDeletePromotion }), []);

  useEffect(() => {
    setPagination({ ...pagination, ...pag });
  }, [pag]);

  return (
    <div className="overflow-x-auto">
      <Table
        columns={columnsTables}
        dataSource={data}
        loading={loading}
        onChange={(pagination, sorter, extra) => onTableChange(pagination, sorter, extra)}
        pagination={pagination}
      />
    </div>
  );
};

export default memo(ContactTable);
