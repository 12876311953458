export const PAYMENT_METHOD_MAP = [
  { value: 0, label: "Visa Credit" },
  { value: 1, label: "Vnpay" },
  { value: 2, label: "Bank Transfer" },
];
export const PRICE_MAP = [
  { value: 0, label: "$" },
  { value: 1, label: "VND" },
  { value: 2, label: "VND" },
];

export const PAYMENT_STATUS_MAP = [
  { value: 0, label: "Fail" },
  { value: 1, label: "Success" },
  { value: 2, label: "Processing" },
];
