import React, { memo, useEffect, useMemo, useState } from "react";
import { Table } from "antd";
import { columns2 } from "./transaction2.config";
interface AppProps {
  data: any;
  loading: boolean;
  onTableChange?: any;
  onStatusChange?: (checked: boolean, userId: string | undefined) => void;
  columnsTable: any;
  pag?: { current: number; pageSize: number; total: number };
  packageList?: {
    key: string;
    label: string;
  }[];
  onSelect?: (packageId: string, userId: string | undefined) => void;
  onOpenModal: (imgProofUrl: string) => void;
  dashboard?: boolean;
}

const showTotal = (total: number, range: any) => {
  return `${range.join("-")} of ${total} items`;
};

const initPagination = {
  current: 1,
  pageSize: 10,
  total: 0,
  showSizeChanger: true,
  pageSizeOptions: ["10", "20", "30"],
  showTotal: showTotal,
};

const UserTable: React.FC<AppProps> = ({
  data,
  loading,
  columnsTable,
  pag,
  packageList,
  onTableChange,
  onSelect,
  onOpenModal,
  dashboard,
}) => {
  const [pagination, setPagination] = useState(initPagination);
  useEffect(() => {
    setPagination({ ...pagination, ...pag });
  }, [pag]);
  const columnsTables = useMemo(() => columnsTable({ onSelect, onOpenModal }), [packageList, pagination]);
  const columnsTables2 = useMemo(() => columns2(onSelect), [packageList, pagination]);
  return (
    <div className="overflow-x-auto">
      <Table
        columns={dashboard ? columnsTables2 : columnsTables}
        onChange={(pagination, sorter, extra) => onTableChange(pagination, sorter, extra)}
        dataSource={data}
        loading={loading}
        pagination={dashboard ? false : pagination}
      />
    </div>
  );
};

export default memo(UserTable);
