import { DatePicker, Form, FormInstance, InputNumber, Modal } from "antd";
import moment from "moment";
import React, { useMemo } from "react";
import { ModalType, PackageType } from "../PackageManagement/package.config";
import { initModalData } from "./user.config";
import { RangePickerProps } from "antd/lib/date-picker";

interface PackageProps {
  modalAttrs: ModalType;
  onCloseModal: () => void;
  form: FormInstance<any>;
  onSubmitForm: (values: PackageType) => void;
}

const dateFormat = "DD/MM/YYYY";

const UserEditModal: React.FC<PackageProps> = ({ modalAttrs, onCloseModal, form, onSubmitForm }) => {
  const disabledDateStartDay: RangePickerProps["disabledDate"] = (current) => {
    // Can not select days before today and today
    return current && current < moment().startOf("day");
  };

  const disabledDateExpiredDay: RangePickerProps["disabledDate"] = (current) => {
    // Can not select days before today and today
    return current && current < form.getFieldValue("startDays");
  };

  return (
    <Modal
      title={modalAttrs.title}
      open={modalAttrs.isOpen}
      onCancel={onCloseModal}
      onOk={() => {
        form.validateFields().then((values: PackageType) => {
          onSubmitForm({
            ...values,
            expiredDays: values?.expiredDays?.toISOString(),
            startDays: values?.startDays?.toISOString(),
          });
        });
      }}
    >
      <Form
        form={form}
        name="modifyPackage"
        layout="vertical"
        initialValues={initModalData}
        onFinish={onSubmitForm}
        autoComplete="off"
      >
        <Form.Item label="Start Days" name="startDays" rules={[{ required: true, message: "Start Days is required" }]}>
          <DatePicker disabledDate={disabledDateStartDay} format={dateFormat} />
        </Form.Item>
        <Form.Item
          label="Expired Days"
          name="expiredDays"
          rules={[{ required: true, message: "Expired Days is required" }]}
        >
          <DatePicker disabledDate={disabledDateExpiredDay} format={dateFormat} />
        </Form.Item>
        <Form.Item
          label="No. of generated per day"
          name="maxGenPerDay"
          rules={[{ required: true, message: "Times Generate Per Day is required" }]}
        >
          <InputNumber min={-1} precision={0} />
        </Form.Item>
        <Form.Item
          label="No. of edit per day"
          name="maxEditPerDay"
          rules={[{ required: true, message: "No. of edit per day is required" }]}
        >
          <InputNumber min={-1} precision={0} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UserEditModal;
