import { Button, Card, Form, Modal, Space } from "antd";
import React, { useEffect, useRef, useState } from "react";

import { AlertPopup, SearchBox, SelectBox, SwitchBox } from "../../../components/common";
import { IParamImage } from "../defineType";
import PromotionModal from "./modal";
import { columns, initModalAttrs, ModalType, PromotionType } from "./promotion.config";
import PromotionTable from "./table";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { createPromotion, deletePromotion, getAllPromotions, updatePromotion } from "../../../helpers/api/func";
import moment from "moment";

const INIT_PAGINATION_VALUES = {
  current: 1,
  pageSize: 10,
  total: 1,
};

const defaultParams = { current: 1, pageSize: 10 };

const SEARCH_FIELD_OPTIONS = [
  { key: "title", label: "Title", default: true },
  { key: "status", label: "Status" },
];

const dateFormat = "YYYY/MM/DD";

const PromotionManagement: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [promotionList, setPromotionList] = useState([]);
  const [paginations, setPagination] = useState<IParamImage>(INIT_PAGINATION_VALUES);
  const [param, setParam] = useState<any>(defaultParams);
  const [modalAttrs, setModalAttrs] = useState<ModalType>(initModalAttrs);
  const [promotionId, setPromotionId] = useState("");
  const [optionSelected, setOptionSelected] = useState("");

  const alertType = useRef<string>("");
  const alertMsg = useRef<string>("");
  const [form] = Form.useForm();

  const handleTableChange = async (pagination: any, sorter: any, extra: any) => {
    setParam({
      ...param,
      limit: pagination.pageSize,
      page: pagination.current,
      sort: extra?.field,
      type: extra?.order === "ascend" ? -1 : 1,
    });
    try {
      setPagination({ ...paginations, current: pagination?.current, pageSize: pagination?.pageSize });
    } catch (error: any) {
      alertType.current = "error";
      alertMsg.current = "Failed to load data, please try again";
      setAlertOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseAlert = () => {
    onResetAlert();
  };

  const onResetAlert = () => {
    alertType.current = "";
    alertMsg.current = "";
    setAlertOpen(false);
  };

  const fetchPromotion = async () => {
    setLoading(true);
    try {
      const result = await getAllPromotions(param);
      const {
        data: { listPromotions },
      } = result;
      if (listPromotions) {
        const promotions = listPromotions.map((list: object, index: number) => {
          return { ...list, key: index };
        });
        setPromotionList(promotions);
      }
      const {
        data: { count },
      } = result;
      if (count !== undefined) {
        setPagination({ current: param.page, pageSize: param.limit, total: count });
      } else {
        setPagination({ current: 1, pageSize: 10, total: 0 });
      }
    } catch (error) {
      alertType.current = "error";
      alertMsg.current = "Failed to load data, please try again";
      setAlertOpen(true);
    } finally {
      setLoading(false);
    }
  };
  const handleSubmitSearch = (keyword: string) => {
    const newParam = { ...defaultParams, title: keyword };
    setParam(newParam);
  };

  const handleSelect = (value: string) => {
    if (value === "status") {
      if (param.title) {
        delete param.title;
      }
      setParam({
        ...param,
        status: 1,
        current: defaultParams.current,
        pageSize: defaultParams.pageSize,
      });
    }
    setOptionSelected(value);
  };

  useEffect(() => {
    fetchPromotion();
  }, [param]);

  const handleAddPromotion = () => {
    setModalAttrs({ title: "Add new Promotion", isOpen: true, type: "add" });
  };

  const handleEditPromotion = (record: PromotionType) => {
    const expiredAt = record.expiredAt ? moment(record.expiredAt, dateFormat) : "";
    const status = record.status === 1 ? true : false;
    setPromotionId(record._id || "");
    setModalAttrs({ title: "Edit Promotion", isOpen: true, type: "edit" });
    form.setFieldsValue({ ...record, expiredAt, status });
  };

  const handleCloseModal = () => {
    setModalAttrs(initModalAttrs);
    form.resetFields();
    setPromotionId("");
  };

  const handleSubmitForm = (values: PromotionType) => {
    switch (modalAttrs.type) {
      case "add":
        addNewPromotion(values);
        break;
      case "edit":
        modifyPromotion(values);
        break;

      default:
        handleCloseModal();
        break;
    }
  };

  const addNewPromotion = async (values: PromotionType) => {
    try {
      const result = await createPromotion(values);
      if (result?.status && result?.status === 200) {
        setAlertOpen(true);
        alertType.current = "success";
        alertMsg.current = "Promotion was created";
        fetchPromotion();
      }
    } catch (error) {
      setAlertOpen(true);
      alertType.current = "error";
      alertMsg.current = "Failed to create new promotion, please try again";
    } finally {
      handleCloseModal();
    }
  };

  const modifyPromotion = async (values: PromotionType) => {
    try {
      const id = promotionId;
      const result = await updatePromotion(id, values);
      if (result?.status && result?.status === 200) {
        setAlertOpen(true);
        alertType.current = "success";
        alertMsg.current = "Promotion was updated";
        fetchPromotion();
      }
    } catch (error) {
      setAlertOpen(true);
      alertType.current = "error";
      alertMsg.current = "Failed to update promotion, please try again";
    } finally {
      handleCloseModal();
    }
  };
  const handleDeletePromotion = async (record: PromotionType) => {
    Modal.confirm({
      title: `Delete promotion`,
      icon: <ExclamationCircleOutlined />,
      content: `Do you want to delete promotion ?`,
      okText: "Confirm",
      cancelText: "Cancel",
      async onOk() {
        try {
          await deletePromotion(record._id || "");
          setAlertOpen(true);
          alertType.current = "success";
          alertMsg.current = "Promotion was deleted";
          fetchPromotion();
        } catch (err) {
          setAlertOpen(true);
          alertType.current = "error";
          alertMsg.current = "Failed to update user, please try again";
        }
      },
    });
  };

  const handleStatusSwitch = (option: boolean) => {
    setParam({
      ...param,
      status: option ? 1 : 0,
      current: param.current,
      pageSize: param.pageSize,
    });
  };

  const renderFilterBar = () => {
    switch (optionSelected) {
      case "status":
        return <SwitchBox onSwitch={handleStatusSwitch} checkedText="Active" unCheckedText="Inactive" />;

      default:
        return <SearchBox onSubmitSearch={handleSubmitSearch} size="large" />;
    }
  };

  return (
    <div className="relative">
      <Card>
        <Space direction="horizontal" size="middle" className="mb-4 flex justify-between">
          <h1 className="font-bold text-xl">Promotion Table</h1>
          <div className="flex gap-4 ">
            <Space direction="horizontal">
              <SelectBox
                options={SEARCH_FIELD_OPTIONS}
                defaultSelected={"title"}
                onSelect={handleSelect}
                label="Filter by:"
              />
              {renderFilterBar()}
            </Space>
            <Button type="primary" onClick={handleAddPromotion} size="large">
              New Promotion
            </Button>
          </div>
        </Space>
        <PromotionTable
          data={promotionList}
          columnsTable={columns}
          loading={loading}
          pag={paginations}
          onTableChange={handleTableChange}
          onDeletePromotion={handleDeletePromotion}
          onOpenModal={handleEditPromotion}
        />
      </Card>
      <AlertPopup
        isOpen={alertOpen}
        type={alertType.current}
        message={alertMsg.current}
        onCloseAlert={handleCloseAlert}
      />
      <PromotionModal
        modalAttrs={modalAttrs}
        onCloseModal={handleCloseModal}
        form={form}
        onSubmitForm={handleSubmitForm}
      />
    </div>
  );
};

export default PromotionManagement;
