import { Button, Modal, Select, Table } from "antd";
import React, { useState, useEffect, useRef } from "react";
import { getTypeImgGenerator, settingImgGenerator } from "../../../helpers/api/func";
import { alertDefault } from "./setting.config";
import { ExclamationCircleFilled } from "@ant-design/icons";

interface Props {
  handleAlert?: any;
}
const SettingTable: React.FC<Props> = ({ handleAlert }) => {
  const [valueSelectState, setValueSelectState] = useState("");
  const [valuSettingPresent, setValuSettingPresent] = useState("");

  const fehctGetSettingDefault = async () => {
    try {
      const result = await getTypeImgGenerator({ type: "IMG_NUMBER" });
      if (result?.message === "ok") {
        const valueOption = result?.data?.value;
        setValueSelectState(valueOption);
        setValuSettingPresent(valueOption);
      }
    } catch (error) {
      setValueSelectState("1");
    }
  };

  useEffect(() => {
    fehctGetSettingDefault();
  }, []);

  const fechCallAPiSetting = async (param: any) => {
    try {
      const result = await settingImgGenerator(param);
      if (result?.message === "ok") {
        setValuSettingPresent(param?.value);
        const alert = {
          isOpen: true,
          type: "success",
          msg: "Save successfully!",
        };
        handleAlert(alert);
      }
    } catch (error: any) {
      const alert = {
        isOpen: true,
        type: "error",
        msg: error?.message,
      };
      handleAlert(alert);
    } finally {
      //   handleAlert(alertDefault);
    }
  };
  const onSelect = (key: string) => {
    setValueSelectState(key);
  };

  const handleCancel = () => {
    setValueSelectState(valuSettingPresent);
  };

  const handleSubmit = () => {
    Modal.confirm({
      title: "Save",
      icon: <ExclamationCircleFilled />,
      content: "Are you sure that save changes?",
      onOk() {
        fechCallAPiSetting({ value: valueSelectState });
      },
    });
  };

  const disabledButton = valueSelectState === valuSettingPresent;

  const columns: any = [
    {
      dataIndex: "type",
      key: "Type",
      title: "Type",
      align: "center",
      width: "200px",
    },
    {
      dataIndex: "Value",
      key: "Value",
      title: "Value",
      align: "center",
      render: () => {
        return (
          <>
            <Select
              value={valueSelectState}
              style={{ width: "250px" }}
              onChange={(key: string) => onSelect(key)}
              options={[
                { value: "1", label: "Show 1 picture image" },
                { value: "4", label: "Show 4 picture image" },
              ]}
            ></Select>
          </>
        );
      },
    },
    {
      dataIndex: "Active",
      key: "Active",
      title: "Active",
      align: "center",
      width: "200px",
      render: () => {
        return (
          <>
            <div>
              <Button disabled={disabledButton} onClick={handleCancel}>
                Cancel
              </Button>
              <Button disabled={disabledButton} type="primary" className="ml-3" onClick={handleSubmit}>
                Save
              </Button>
            </div>
          </>
        );
      },
    },
  ];

  const data = [{ key: 1, type: "AI Image Generator API", dataIndex: 1 }];
  return (
    <>
      <Table columns={columns} dataSource={data} pagination={false} />
    </>
  );
};

export default SettingTable;
