interface IColumns {
  title: string;
  dataIndex: string;
  key: string;
  align: "center" | "left" | "right";
  sorter?: boolean;
  defaultSortOrder?: string;
}

export const columns: IColumns[] = [
  {
    title: "First Name",
    dataIndex: "firstName",
    key: "firstName",
    align: "left",
    sorter: true,
    defaultSortOrder: "descend",
  },
  {
    title: "Last Name",
    dataIndex: "lastName",
    key: "lastName",
    align: "left",
    sorter: true,
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
    align: "left",
    sorter: true,
  },
  {
    title: "Note",
    dataIndex: "note",
    key: "note",
    align: "left",
  },
  {
    title: "Phone",
    dataIndex: "phone",
    key: "phone",
    align: "left",
  },
];
