import { Button, Card, Form, Tabs, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { AlertPopup } from "../../../components/common";
import { LEVEL_PACKAGE, TYPE_PACKAGE } from "../../../constants/common.constant";
import { createPackage, deletePackages, getAllPackages, updatePackage } from "../../../helpers/api/func";
import PackageModal from "./modal";
import { columns, initModalAttrs, ModalType, PackageType } from "./package.config";
import PackageTable from "./table";

const PackageManagement: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [alertMsg, setAlertMsg] = useState("");
  const [packageList, setPackageList] = useState([]);
  const [modalAttrs, setModalAttrs] = useState<ModalType>(initModalAttrs);
  const [packageId, setPackageId] = useState("");
  const [form] = Form.useForm();

  const handleTableChange = async (pagination: any, sorter: any, extra: any) => {
    // console.log({ pagination });
  };

  const handleCloseAlert = () => {
    onResetAlert();
  };

  const onResetAlert = () => {
    setAlertOpen(false);
    setAlertType("");
    setAlertMsg("");
  };

  const fetchPackage = async () => {
    setLoading(true);
    try {
      const result = await getAllPackages();
      const {
        data: { listPackage },
      } = result;
      if (listPackage && listPackage.length > 0) {
        const packages = listPackage.map((l: object, i: number) => {
          return { ...l, key: i };
        });
        setPackageList(packages);
      }
    } catch (error) {
      setAlertOpen(true);
      setAlertType("error");
      setAlertMsg("Failed to load data, please try again");
    } finally {
      setLoading(false);
    }
  };

  const addNewPackage = async (values: PackageType) => {
    try {
      const result = await createPackage(values);
      if (result?.status && result?.status === 200) {
        setAlertOpen(true);
        setAlertType("success");
        setAlertMsg("Package was created");
        fetchPackage();
      }
    } catch (error) {
      setAlertOpen(true);
      setAlertType("error");
      setAlertMsg("Failed to create new package, please try again");
    } finally {
      handleCloseModal();
    }
  };

  const modifyPackage = async (values: PackageType) => {
    try {
      const id = packageId;
      const result = await updatePackage(id, values);
      if (result?.status && result?.status === 200) {
        setAlertOpen(true);
        setAlertType("success");
        setAlertMsg("Package was updated");
        fetchPackage();
      }
    } catch (error) {
      setAlertOpen(true);
      setAlertType("error");
      setAlertMsg("Failed to update package, please try again");
    } finally {
      handleCloseModal();
    }
  };

  useEffect(() => {
    fetchPackage();
  }, []);

  const handleAddPackage = () => {
    setModalAttrs({ title: "Add new Package", isOpen: true, type: "add" });
  };

  const handleEditPackage = (record: PackageType) => {
    setPackageId(record.id || "");
    setModalAttrs({ title: "Edit Package", isOpen: true, type: "edit" });
    form.setFieldsValue(record);
  };

  const handleCloseModal = () => {
    setModalAttrs(initModalAttrs);
    form.resetFields();
    setPackageId("");
  };

  const handleSubmitForm = (values: PackageType) => {
    switch (modalAttrs.type) {
      case "add":
        addNewPackage(values);
        break;
      case "edit":
        modifyPackage(values);
        break;

      default:
        handleCloseModal();
        break;
    }
  };

  const handleDeletePackage = (record: any) => {
    if (
      record.level === LEVEL_PACKAGE.FREE ||
      record.level === LEVEL_PACKAGE.ADMIN ||
      record.level === LEVEL_PACKAGE.CUSTOM
    ) {
      setAlertOpen(true);
      setAlertType("error");
      setAlertMsg(`Package ${record.title} is cann't delete`);
      return;
    }
    Modal.confirm({
      title: `Delete package`,
      icon: <ExclamationCircleOutlined />,
      content: `Do you want to delete package ?`,
      okText: "Confirm",
      cancelText: "Cancel",
      async onOk() {
        try {
          await deletePackages(record.id);
          setAlertOpen(true);
          setAlertType("success");
          setAlertMsg("Package was deleted");
          fetchPackage();
        } catch (err) {
          setAlertOpen(true);
          setAlertType("error");
          setAlertMsg("Failed to update user, please try again");
        }
      },
    });
  };
  const item = [
    {
      label: "For Sale",
      key: "item-1",
      children: (
        <PackageTable
          data={packageList.filter((pk: any) => pk.typePackage === TYPE_PACKAGE.SALE)}
          columnsTable={columns}
          loading={loading}
          onDeletePackage={handleDeletePackage}
          onTableChange={handleTableChange}
          onOpenModal={handleEditPackage}
        />
      ),
    },
    {
      label: "For Custom",
      key: "item-2",
      children: (
        <PackageTable
          data={packageList.filter((pk: any) => pk.typePackage === TYPE_PACKAGE.CUSTOM)}
          columnsTable={columns}
          loading={loading}
          onDeletePackage={handleDeletePackage}
          onTableChange={handleTableChange}
          onOpenModal={handleEditPackage}
        />
      ),
    },
  ];
  return (
    <div className="relative">
      <Card>
        <div className="flex items-center justify-between">
          <h1 className="font-bold text-xl mb-6">Package Table</h1>

          <Button type="primary" onClick={handleAddPackage}>
            New Package
          </Button>
        </div>
        <Tabs type="card" items={item} />
      </Card>
      <PackageModal
        modalAttrs={modalAttrs}
        onCloseModal={handleCloseModal}
        form={form}
        onSubmitForm={handleSubmitForm}
      />
      <AlertPopup isOpen={alertOpen} type={alertType} message={alertMsg} onCloseAlert={handleCloseAlert} />
    </div>
  );
};

export default PackageManagement;
