import React, { useState } from "react";
import { Layout } from "antd";
import ProfileDropdown from "../components/common/profileDropdown";
import { useLocation } from "react-router";

const { Header } = Layout;

interface Props {
  collapsed: boolean;
  onHeaderClick: () => void;
}

const MainHeader = (props: Props) => {
  const location = useLocation();
  return (
    <Header className="flex justify-between p-0  bg-[#fafafa] ">
      <div className="px-6">
        <div> Pages / Table</div>
        <div className="-mt-[42px] capitalize font-bold">{location?.pathname?.split("/")?.[1] || "Dashboard"}</div>
      </div>
      <ProfileDropdown />
    </Header>
  );
};

export default MainHeader;
