import { Card, Modal, Space } from "antd";
import React, { useEffect, useState, useCallback } from "react";
import { AlertPopup, SearchBox, SelectBox } from "../../../components/common";
import { getAllTransaction, putStatusTransaction } from "../../../helpers/api/func";
import { ParamTypes } from "../defineType";
import { columns } from "./transaction.config";
import UserTable from "./table";
import { useMutation, useQuery, useQueryClient } from "react-query";
import RadioBox from "../../../components/common/radioBox";
import { convertParams } from "../../../utils/common";
import { Link } from "react-router-dom";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import ImageProofModal from "./modal";
const defaultParams = { current: 1, pageSize: 10, status: 1 };
export const defaultParamsDashboard = { current: 1, pageSize: 5, status: 1 };

const selectOptions = [
  { key: "email", label: "Email" },
  { key: "status", label: "Status" },
];
const TransactionManagement: React.FC<any> = ({ isInput = true, dashboard }: any) => {
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [alertMsg, setAlertMsg] = useState("");
  const [userParams, setUserParams] = useState<ParamTypes>(dashboard ? defaultParamsDashboard : defaultParams);
  const [imgProofUrl, setImgProofUrl] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [pagination, setPagination] = useState({
    current: defaultParams.current,
    pageSize: defaultParams.pageSize,
    total: 0,
  });
  const [optionSelected, setOptionSelected] = useState("");
  const queryClient = useQueryClient();
  const { isLoading, isError, data } = useQuery(["transaction", userParams], (res) => {
    return getAllTransaction(convertParams(res?.queryKey?.[1], optionSelected, selectOptions));
  });
  const { mutate } = useMutation(putStatusTransaction, {
    onSuccess: () => queryClient.invalidateQueries(["transaction", userParams]),
  });
  useEffect(() => {
    if (isError) {
      setAlertOpen(true);
      setAlertType("error");
      setAlertMsg("Failed to load data, please try again");
    }
    setPagination({ ...pagination, total: data?.data?.total || 0 });
  }, [data, isError]);
  const handlerSelectStatus = async (key: string, record: any) => {
    const body = {
      packageId: record?.packageId?.id,
      userId: record?.userId?.id,
      payStatus: Number(key),
    };
    try {
      Modal.confirm({
        title: "Update Payment status",
        icon: <ExclamationCircleOutlined />,
        content: "Do you want to update Payment Status for this user?",
        okText: "Confirm",
        cancelText: "Cancel",
        onOk() {
          mutate({ record, body });
        },
      });
    } catch (error) {
      setAlertOpen(true);
      setAlertType("error");
      setAlertMsg("Failed to load data, please try again");
    }
  };

  const resetAlert = () => {
    setAlertOpen(false);
    setAlertType("");
    setAlertMsg("");
  };
  const handleTableChange = (pagination: any, sorter: any, extra: any) => {
    const { current, pageSize } = pagination;
    const { field, order } = extra;
    setUserParams({ ...userParams, pageSize, current, sortField: field, sortOrder: order });
    setPagination({ current: current, pageSize: pageSize, total: data?.data?.total || 0 });
  };

  const handleSelect = (value: string) => {
    setUserParams({
      ...userParams,
      filterField: "status",
      filterValue: "",
      current: defaultParams.current,
      pageSize: defaultParams.pageSize,
    });
    setOptionSelected(value);
  };
  const handleFilterPackage = (key: string) => {
    setUserParams({
      ...userParams,
      filterField: "status",
      filterValue: Number(key),
      current: defaultParams.current,
      pageSize: defaultParams.pageSize,
    });
  };
  const handleSubmitSearch = (keyword: string) => {
    setUserParams({
      ...userParams,
      filterValue: keyword,
      current: defaultParams.current,
      pageSize: defaultParams.pageSize,
    });
  };
  const renderFilterBar = () => {
    switch (optionSelected) {
      case "status":
        return (
          <RadioBox
            options={[
              { key: "0", label: "Fail" },
              { key: "1", label: "Success" },
              { key: "2", label: "Processing" },
            ]}
            defaultValue={""}
            onRadioChange={(key: string) => handleFilterPackage(key)}
          />
        );
      default:
        return <SearchBox onSubmitSearch={handleSubmitSearch} size="large" />;
    }
  };

  const handleOpenModal = useCallback(
    (imgUrl: string) => {
      setImgProofUrl(imgUrl);
      setIsModalOpen(true);
    },
    [imgProofUrl, isModalOpen],
  );

  const handleCloseModal = useCallback(() => {
    setImgProofUrl(null);
    setIsModalOpen(false);
  }, [imgProofUrl, isModalOpen]);

  // const handleStatusChange = (checked: boolean, userId: string | undefined) => {};

  return (
    <div className="relative">
      <Card>
        <Space direction="vertical" size="middle" className="flex">
          <Space direction="horizontal" size="middle" className="flex justify-between">
            <h1 className="font-bold text-xl">Transaction history</h1>
            {!isInput && (
              <Link to={"/transaction"} className="text-blue-500 hover:opacity-70 cursor-pointer text-[17px]">
                Transaction Details
              </Link>
            )}
            {isInput && (
              <div className="flex gap-4">
                <div>
                  <SelectBox
                    options={selectOptions}
                    defaultSelected="email"
                    onSelect={handleSelect}
                    label="Filter by:"
                  />
                </div>
                <div>{renderFilterBar()}</div>
              </div>
            )}
          </Space>
          <UserTable
            data={data?.data?.listPayment}
            onTableChange={handleTableChange}
            loading={isLoading}
            columnsTable={columns}
            pag={pagination}
            onSelect={handlerSelectStatus}
            dashboard={dashboard}
            onOpenModal={handleOpenModal}
          />
        </Space>
        <ImageProofModal
          isOpen={isModalOpen}
          title={"Image information"}
          imgSrc={imgProofUrl}
          onClose={handleCloseModal}
        />
      </Card>
      <AlertPopup isOpen={alertOpen} type={alertType} message={alertMsg} onCloseAlert={resetAlert} />
    </div>
  );
};

export default TransactionManagement;
