import { Card, Modal, Space } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { AlertPopup, SearchBox, SelectBox, SwitchBox } from "../../../components/common";
import { PARAMS_IMAGE, SELECT_OPTIONS } from "../../../constants/image.constant";
import { deleteImages, getAllImages } from "../../../helpers/api/func";
import { columns } from "./image.config";
import { ImageProps, IParamImage } from "../defineType";
import ImageModal from "./modal";
import ImageTable from "./table";
import { ExclamationCircleOutlined } from "@ant-design/icons";

const initImageData = {
  prompt: "",
  numberoflike: 0,
  status: false,
  createdAt: "",
  imageUrl: "",
};

const ManageImages: React.FC = () => {
  const [tableData, setTableData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [alertMsg, setAlertMsg] = useState("");
  const [optionSelected, setOptionSelected] = useState("");
  const [paginations, setPagination] = useState<IParamImage>(PARAMS_IMAGE);
  const [param, setParam] = useState<any>({ limit: 10, page: 1 });
  const [imageData, setImageData] = useState<ImageProps>(initImageData);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleTableChange = async (pagination: any, sorter: any, extra: any) => {
    setParam({
      ...param,
      limit: pagination?.pageSize,
      page: pagination?.current,
      type: extra?.order === "ascend" ? -1 : 1,
      sort: extra.field || "createdAt",
    });
    try {
      setPagination({ ...paginations, current: pagination?.current, pageSize: pagination?.pageSize });
    } catch (error: any) {
      setAlertOpen(true);
      setAlertType("error");
      setAlertMsg("Failed to load data, please try again");
    } finally {
      setLoading(false);
    }
  };

  const handleCloseAlert = () => {
    onResetAlert();
  };

  const onResetAlert = () => {
    setAlertOpen(false);
    setAlertType("");
    setAlertMsg("");
  };

  const handleSubmitSearch = (keyword: string) => {
    if (optionSelected !== "isPublic") {
      setParam({ ...param, prompt: keyword, limit: 10, page: 1 });
      setPagination({ current: 1, pageSize: 10, total: 0 });
    }
  };

  const handleSelect = (value: string) => {
    if (value === "prompt") {
      delete param.isPublic;
    }
    if (value === "isPublic") {
      setParam({ limit: 10, page: 1, isPublic: true });
      setPagination({ current: 1, pageSize: 10, total: 0 });
    }
    setOptionSelected(value);
  };

  const handleSwitch = (option: boolean) => {
    setParam({ limit: 10, page: 1, isPublic: option });
    setPagination({ current: 1, pageSize: 10, total: 0 });
  };

  const getAllImage = async () => {
    setLoading(true);
    try {
      const res = await getAllImages(param);
      const {
        data: { count },
      } = res;
      setTableData(
        res?.data?.listImage?.map((item: any, key: number) => {
          return { ...item, key };
        }),
      );
      if (count) {
        setPagination({ current: param.page, pageSize: param.limit, total: count });
      } else {
        setPagination({ current: 1, pageSize: 10, total: 0 });
      }
    } catch (error: any) {
      setAlertOpen(true);
      setAlertType("error");
      setAlertMsg("Failed to load data, please try again");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllImage();
  }, [param]);

  const handleOpenModal = useCallback(
    (record: ImageProps) => {
      setImageData(record);
      setIsModalOpen(true);
    },
    [imageData, isModalOpen],
  );

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
    setImageData(initImageData);
  }, [isModalOpen, imageData]);

  const handleDeletePackage = (record: any) => {
    console.log("vo", record);

    Modal.confirm({
      title: `Delete Image`,
      icon: <ExclamationCircleOutlined />,
      content: `Are you sure you want to delete the image?`,
      okText: "Confirm",
      cancelText: "Cancel",
      async onOk() {
        try {
          await deleteImages(record.id);
          setAlertOpen(true);
          setAlertType("success");
          setAlertMsg("Image was deleted");
          getAllImage();
        } catch (err) {
          console.log(err);
          setAlertOpen(true);
          setAlertType("error");
          setAlertMsg("Failed to update image, please try again");
        }
      },
    });
  };

  return (
    <div className="relative">
      <Card>
        <Space direction="vertical" size="middle" className="flex">
          <Space direction="horizontal" size="middle" className="flex justify-between">
            <h1 className="font-bold text-xl">Image Table</h1>
            <div className="flex gap-4">
              <div>
                <SelectBox
                  options={SELECT_OPTIONS}
                  defaultSelected="prompt"
                  onSelect={handleSelect}
                  label="Filter by:"
                />
              </div>
              <div>
                {optionSelected === "isPublic" ? (
                  <SwitchBox onSwitch={handleSwitch} checkedText="Public" unCheckedText="Private" />
                ) : (
                  <SearchBox onSubmitSearch={handleSubmitSearch} size="large" />
                )}
              </div>
            </div>
          </Space>
          <ImageTable
            data={tableData}
            columnsTable={columns}
            loading={loading}
            onTableChange={handleTableChange}
            pag={paginations}
            onOpenModal={handleOpenModal}
            onDeleteImage={handleDeletePackage}
          />
          <ImageModal
            isOpen={isModalOpen}
            title={"Image information"}
            data={imageData}
            onClose={handleCloseModal}
            type="view"
          />
        </Space>
      </Card>
      <AlertPopup isOpen={alertOpen} type={alertType} message={alertMsg} onCloseAlert={handleCloseAlert} />
    </div>
  );
};

export default ManageImages;
