import React from "react";
import { Routes, Route } from "react-router-dom";
//Layouts
import NonAuthLayout from "../layouts/NonAuthLayout";
import VerticalLayouts from "../layouts/VerticalLayouts";
//routes
import { authProtectedRoutes, publicRoutes } from "./allRoutes";

const Index = () => {
  return (
    <Routes>
      <Route element={<VerticalLayouts />}>
        {authProtectedRoutes.map((route, idx) => (
          <Route path={route.path} element={route.element} key={idx} />
        ))}
      </Route>
      <Route element={<NonAuthLayout />}>
        {publicRoutes.map((route, idx) => (
          <Route path={route.path} element={route.element} key={idx} />
        ))}
      </Route>
    </Routes>
  );
};

export default Index;
