import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import { Layout } from "antd";

import MainHeader from "./MainHeader";
import MainSidebar from "./MainSidebar";
import MainFooter from "./MainFooter";
import "../assets/scss/layout.scss";
import { AuthProtected } from "../routes/authProtected";

const { Content } = Layout;

const VerticalLayouts: React.FC = () => {
  const [collapsed, setCollapsed] = useState(false);

  const handleHeaderClick = () => {
    setCollapsed(!collapsed);
  };

  return (
    <AuthProtected>
      <Layout>
        <MainSidebar collapsed={collapsed} />
        <Layout className="site-layout">
          <MainHeader collapsed={collapsed} onHeaderClick={handleHeaderClick} />
          <Content className="content">
            <Outlet />
          </Content>
        </Layout>
      </Layout>
      <MainFooter />
    </AuthProtected>
  );
};

export default VerticalLayouts;
