import React, { memo, useEffect, useMemo, useState } from "react";
import { Table } from "antd";
import { PackageType } from "./package.config";

const initPagination = { current: 1, pageSize: 10, total: 0 };
type pagination = { current: number; pageSize: number; total: number };

interface AppProps {
  data: PackageType[];
  loading: boolean;
  onTableChange: (pagination: object, sorter: any, extra: object) => void;
  onOpenModal: (record: PackageType) => void;
  onDeletePackage: (id: any) => void;
  columnsTable: any;
  pag?: pagination;
}

const PackageTable: React.FC<AppProps> = ({
  data,
  loading,
  onTableChange,
  columnsTable,
  pag,
  onOpenModal,
  onDeletePackage,
}) => {
  const [pagination, setPagination] = useState<pagination>(initPagination);

  useEffect(() => {
    if (pag) {
      setPagination(pag);
    }
  }, [pag]);
  const columnsTables = useMemo(() => columnsTable({ onOpenModal, onDeletePackage }), []);

  return (
    <div className="overflow-x-auto">
      <Table
        columns={columnsTables}
        dataSource={data}
        loading={loading}
        onChange={(pagination, sorter, extra) => onTableChange(pagination, sorter, extra)}
        pagination={pagination}
      />
    </div>
  );
};

export default memo(PackageTable);
