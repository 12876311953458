import { Button } from "antd";
import React, { useState } from "react";
import { BiArrowBack } from "react-icons/bi";
import Lottie from "react-lottie-player";
import { useNavigate } from "react-router";
import ForgotPwdImg from "../../../assets/images/forgot-password.json";
import FormCheckAndUpdatePwd from "./FormCheckAndUpdatePwd";
import FormSendMailVerify from "./FormSendMailVerify";

const ForgotPassword: React.FC = () => {
  const [isSendMail, setIsSendMail] = useState<boolean>(false);
  const navigate = useNavigate();

  return (
    <>
      <div className="container mx-auto p-6 min-h-screen">
        <Button onClick={() => navigate("/signin")}>
          <BiArrowBack className="w-5 h-5" />
        </Button>
        <div className="container min-h-screen flex items-center justify-center flex-col md:flex-row">
          <div className="w-full md:w-1/2 h-fit">
            <Lottie loop animationData={ForgotPwdImg} play className="block w-4/5 md:w-full mb-8 md:mb-0 mx-auto" />
          </div>
          <div className="w-full md:w-1/2 h-fit pl-0 sm:pl-4 lg:pl-12">
            {isSendMail ? <FormCheckAndUpdatePwd /> : <FormSendMailVerify setIsSendMail={setIsSendMail} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
