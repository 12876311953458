import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT;
axios.defaults.headers.post["Content-Type"] = "application/json";

axios.interceptors.response.use(
  function (response) {
    return response.data ? response.data : response;
  },
  function (error) {
    let message;
    switch (error.response.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 401:
        message = "Invalid credentials";
        break;
      case 404:
        message = "Sorry! the data you are looking for could not be found";
        break;
      default:
        message = error.message || error;
    }
    return Promise.reject({ message: error.response?.data?.data?.message || "", code: error.response.status });
  },
);

class APIClient {
  axios: any;

  constructor() {
    this.axios = axios;
  }

  checkUser() {
    const user = localStorage.getItem("authUser");
    if (user) {
      const { accessToken } = JSON.parse(user);
      this.axios.defaults.headers.common = {
        Authorization: `Bearer ${accessToken}`,
      };
    }
  }

  get = (url: string, params: any) => {
    this.checkUser();
    let response;

    const paramKeys: string[] = [];

    if (params) {
      Object.keys(params).map((key: string) => {
        paramKeys.push(key + "=" + params[key]);
        return paramKeys;
      });

      const queryString = paramKeys && paramKeys.length ? paramKeys.join("&") : "";
      response = this.axios.get(`${url}?${queryString}`, params);
    } else {
      response = this.axios.get(`${url}`, params);
    }

    return response;
  };

  create = (url: string, data: object) => {
    this.checkUser();
    return this.axios.post(url, data);
  };

  update = (url: string, data: object) => {
    this.checkUser();
    return this.axios.put(url, data);
  };

  delete = (url: string, config: object) => {
    this.checkUser();
    return this.axios.delete(url, { ...config });
  };
}

const getLoggedinUser = () => {
  const user = localStorage.getItem("authUser");

  if (!user) {
    return null;
  } else {
    const { expireTime } = JSON.parse(user);
    if (new Date().getTime() > expireTime) {
      localStorage.removeItem("authUser");
      return null;
    } else {
      return JSON.parse(user);
    }
  }
};

export { APIClient, getLoggedinUser };
