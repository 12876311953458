import React, { useEffect, useState } from "react";
import { Radio, RadioChangeEvent } from "antd";

type Option = {
  key: string;
  label: string;
};

interface AppProps {
  defaultValue?: string;
  options: Option[];
  onRadioChange: (key: string) => void;
}

const RadioBox: React.FC<AppProps> = ({ defaultValue, options, onRadioChange }) => {
  const [initValue, setInitValue] = useState("");

  useEffect(() => {
    if (options && options.length > 0 && defaultValue) {
      setInitValue(defaultValue);
    }
  }, [defaultValue, options]);

  const onChange = (e: RadioChangeEvent) => {
    onRadioChange(e.target.value);
    setInitValue(e.target.value);
  };

  return (
    <Radio.Group value={initValue} buttonStyle="solid" onChange={onChange}>
      {options.map((op, idx) => (
        <Radio.Button value={op.key} key={idx}>
          {op.label}
        </Radio.Button>
      ))}
    </Radio.Group>
  );
};

export default RadioBox;
