import React from "react";
import { Layout } from "antd";
import "../assets/scss/layout.scss";

const { Footer } = Layout;

const MainFooter = () => {
  return (
    <Footer className="flex justify-center">
      © Copyright 2022 DopikAI -
      <a className="" href="https://www.sotatek.com/" target="_blank" rel="noopener noreferrer">
        SotaTek
      </a>
      | All Rights Reserved.
    </Footer>
  );
};

export default MainFooter;
