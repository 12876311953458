/* eslint-disable prefer-const */
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Card, Form, Modal, Space } from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AlertPopup, SearchBox, SelectBox, SwitchBox } from "../../../components/common";
import RadioBox from "../../../components/common/radioBox";
import { LEVEL_PACKAGE, initUserData } from "../../../constants/common.constant";
import {
  getAllPackages,
  getAllUsers,
  updatePackageUser,
  updateUserStatus,
  upgradeBatchPackage,
  upgradePackage,
} from "../../../helpers/api/func";
import { convertParams } from "../../../utils/common";
import { ModalType } from "../PackageManagement/package.config";
import { defaultParamsDashboard } from "../Transaction/transaction";
import { UserType } from "../defineType";
import UserModal from "./modal";
import UserEditModal from "./modalEdit";
import UserTable from "./table";
import { columns, initModalAttrs } from "./user.config";
import UserPackageDropdown from "./userPackageDropdown";

const selectOptions = [{ key: "email", label: "Email" }];

const defaultParams = { current: 1, pageSize: 10, status: 1 };

interface ParamTypes {
  current?: number;
  pageSize?: number;
  sortField?: string;
  sortOrder?: string;
  filterField?: string;
  filterValue?: string | number;
}

interface PackageType {
  key: string;
  label: string;
}
interface PackageItem {
  id: string;
  title: string;
  typePackage: string | number;
}

const UserManagement: React.FC<any> = ({ isInput = true, dashboard }: any) => {
  const [tableData, setTableData] = useState<UserType[]>([]);
  const [loading, setLoading] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [alertMsg, setAlertMsg] = useState("");
  const [optionSelected, setOptionSelected] = useState("");
  const [userParams, setUserParams] = useState<ParamTypes>(dashboard ? defaultParamsDashboard : defaultParams);
  const [pagination, setPagination] = useState({
    current: defaultParams.current,
    pageSize: defaultParams.pageSize,
    total: 0,
  });
  const [packageList, setPackageList] = useState<PackageType[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRows, setSelectedRows] = useState<UserType[]>([]);
  const [packageSelected, setPackageSelected] = useState("");

  const [userData, setUserData] = useState<UserType>(initUserData);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [modalAttrs, setModalAttrs] = useState<ModalType>(initModalAttrs);
  const [packageUserId, setPackageUserId] = useState("");
  const [form] = Form.useForm();

  const fetchUser = async (params: any) => {
    setLoading(true);
    try {
      const users = await getAllUsers(params);
      const {
        data: { listUsers, total },
      } = users;
      if (listUsers) {
        const users = listUsers.map((u: object, i: number) => {
          return { ...u, key: i };
        });
        setTableData(users);
      } else {
        setTableData([]);
      }
      if (total) {
        setPagination({ current: params.page, pageSize: params.limit, total });
      } else {
        setPagination({ current: 1, pageSize: 10, total: 0 });
      }
    } catch (error) {
      setAlertOpen(true);
      setAlertType("error");
      setAlertMsg("Failed to load data, please try again");
    } finally {
      setLoading(false);
    }
  };

  const fetchPackage = async () => {
    try {
      const result = await getAllPackages();
      const {
        data: { listPackage },
      } = result;
      if (listPackage && listPackage.length > 0) {
        const packages = listPackage
          .filter((lp: any) => lp.level !== LEVEL_PACKAGE.CUSTOM)
          .map((l: PackageItem) => {
            return { key: l.id, label: l.title, typePackage: l.typePackage };
          });
        setPackageList(packages);
      }
    } catch (error) {
      console.log({ error });
    }
  };

  useEffect(() => {
    fetchPackage();
  }, []);

  useEffect(() => {
    fetchUser(convertParams(userParams, optionSelected, selectOptions));
  }, [userParams]);

  const handleTableChange = (pagination: any, sorter: any, extra: any) => {
    const { current, pageSize } = pagination;
    const { field, order } = extra;
    setUserParams({ ...userParams, pageSize, current, sortField: field, sortOrder: order });
    setSelectedRowKeys([]);
  };

  const handleStatusChange = (param: any, checked: any, userId: string | undefined) => {
    Modal.confirm({
      title: `Update user ${param === "status" ? "status" : "is early adopter"}`,
      icon: <ExclamationCircleOutlined />,
      content: `Do you want to update ${param === "status" ? "status" : "is early adopter"} for this user?`,
      okText: "Confirm",
      cancelText: "Cancel",
      async onOk() {
        try {
          await new Promise((resolve, reject) => {
            updateStatus(resolve, reject, param, checked, userId);
          });
          setAlertOpen(true);
          setAlertType("success");
          setAlertMsg("Status was updated");
          fetchUser(convertParams(userParams, optionSelected, selectOptions));
        } catch (err) {
          setAlertOpen(true);
          setAlertType("error");
          setAlertMsg("Failed to update user, please try again");
        }
      },
    });
  };

  const updateStatus = async (resolve: any, reject: any, param: any, checked: any, userId: string | undefined) => {
    const status = checked ? 1 : 0;
    try {
      const params = { [param]: status, userId };
      const result = await updateUserStatus(params);
      if (result?.status && result?.status === 200) {
        resolve("");
      }
    } catch (error) {
      reject("error");
    }
  };

  const handleCloseAlert = () => {
    resetAlert();
  };

  const resetAlert = () => {
    setAlertOpen(false);
    setAlertType("");
    setAlertMsg("");
  };

  const handleSubmitSearch = (keyword: string) => {
    setUserParams({
      ...userParams,
      filterValue: keyword,
      current: defaultParams.current,
      pageSize: defaultParams.pageSize,
    });
  };

  const handleSelect = (value: string) => {
    if (value === "status") {
      setUserParams({
        ...userParams,
        filterField: "status",
        filterValue: 1,
        current: defaultParams.current,
        pageSize: defaultParams.pageSize,
      });
    }
    if (value === "package") {
      setUserParams({
        ...userParams,
        filterField: "package",
        filterValue: packageList && packageList.length > 0 ? packageList[0].key : "",
        current: defaultParams.current,
        pageSize: defaultParams.pageSize,
      });
    }
    setOptionSelected(value);
  };

  const handleStatusSwitch = (option: boolean) => {
    setUserParams({
      ...userParams,
      filterField: "status",
      filterValue: option ? 1 : 0,
      current: defaultParams.current,
      pageSize: defaultParams.pageSize,
    });
  };

  const handleViewUser = useCallback(
    (record: UserType) => {
      setUserData(record);
      setIsModalOpen(true);
      setModalType("view");
      setModalTitle("User information");
    },
    [userData, isModalOpen],
  );

  const handleEditPropertyUser = useCallback((record: UserType) => {
    setUserData(record);
    setPackageUserId(record?.userPackage?.id as string);
    form.setFieldsValue(record?.userPackage);
    form.setFieldValue("startDays", moment(record?.userPackage?.startDays));
    form.setFieldValue("expiredDays", moment(record?.userPackage?.expiredDays));
    setModalAttrs({ title: "Edit Package User", isOpen: true, type: "edit" });
  }, []);

  const handleSubmitForm = async (values: any) => {
    try {
      const id = packageUserId;
      const result = await updatePackageUser(id, values);
      if (result?.status && result?.status === 200) {
        setAlertOpen(true);
        setAlertType("success");
        setAlertMsg("Package was updated");
        fetchUser(convertParams(userParams, optionSelected, selectOptions));
      }
    } catch (error) {
      setAlertOpen(true);
      setAlertType("error");
      setAlertMsg("Failed to update package, please try again");
    } finally {
      handleCloseEditModal();
    }
  };

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
    setUserData(initUserData);
  }, [isModalOpen, userData]);

  const handleCloseEditModal = () => {
    setModalAttrs(initModalAttrs);
    form.resetFields();
    setUserData(initUserData);
    setPackageUserId("");
  };

  const handleSelectPackage = (packageId: string, userId: string | undefined) => {
    Modal.confirm({
      title: "Update user package",
      icon: <ExclamationCircleOutlined />,
      content: "Do you want to update package for this user?",
      okText: "Confirm",
      cancelText: "Cancel",
      onOk() {
        return new Promise((resolve, reject) => {
          updateUserPackage(resolve, reject, packageId, userId);
        })
          .then(() => {
            setAlertOpen(true);
            setAlertType("success");
            setAlertMsg("Package was updated");
            fetchUser(convertParams(userParams, optionSelected, selectOptions));
          })
          .catch((err) => {
            setAlertOpen(true);
            setAlertType("error");
            setAlertMsg("Failed to update user, please try again");
          });
      },
    });
  };

  const updateUserPackage = async (resolve: any, reject: any, packageId: string, userId: string | undefined) => {
    try {
      const params = { packageId, userId };
      const result = await upgradePackage(params);
      if (result?.status && result?.status === 200) {
        resolve("");
      }
    } catch (error) {
      reject("error");
    }
  };

  const handleFilterPackage = (key: string) => {
    setUserParams({
      ...userParams,
      filterField: "package",
      filterValue: key,
      current: defaultParams.current,
      pageSize: defaultParams.pageSize,
    });
  };

  const renderFilterBar = () => {
    switch (optionSelected) {
      case "status":
        return <SwitchBox onSwitch={handleStatusSwitch} checkedText="Active" unCheckedText="Inactive" />;
      case "package":
        return (
          <RadioBox
            options={packageList}
            defaultValue={packageList && packageList.length > 0 ? packageList[0].key : ""}
            onRadioChange={(key: string) => handleFilterPackage(key)}
          />
        );

      default:
        return <SearchBox onSubmitSearch={handleSubmitSearch} size="large" />;
    }
  };
  const handleSelectChange = (selectedRowKeys: React.Key[], selectedRows: UserType[]) => {
    setSelectedRows(selectedRows);
    setSelectedRowKeys(selectedRowKeys);
  };
  const handleSelectUserPackage = (key: string) => {
    setPackageSelected(key);
  };
  useEffect(() => {
    if (selectedRowKeys.length === 0) {
      setPackageSelected("");
      setSelectedRows([]);
    }
  }, [selectedRowKeys]);
  useEffect(() => {
    if (packageSelected) {
      handleUpdateMultiUserPg();
    }
  }, [packageSelected]);

  const handleUpdateMultiUserPg = async () => {
    const userIds = selectedRows.map((i) => i.id);
    Modal.confirm({
      title: "Update package for all users",
      icon: <ExclamationCircleOutlined />,
      content: "Do you want to update?",
      okText: "Confirm",
      cancelText: "Cancel",
      async onOk() {
        try {
          const result = await upgradeBatchPackage({
            listUserId: userIds,
            packageId: packageSelected,
          });
          if (result?.status && result?.status === 200) {
            setAlertOpen(true);
            setAlertType("success");
            setAlertMsg("Users was created");
            fetchUser(convertParams(userParams, optionSelected, selectOptions));
            setSelectedRowKeys([]);
          }
        } catch (err) {
          setAlertOpen(true);
          setAlertType("error");
          setAlertMsg("Failed to update users, please try again");
        }
      },
    });
  };

  return (
    <div className="relative">
      <Card>
        <Space direction="vertical" size="middle" className="flex">
          <Space direction="horizontal" size="middle" className="flex justify-between">
            <h1 className="font-bold text-xl">{isInput ? "User Table" : "New Users"}</h1>
            {!isInput && (
              <Link to={"/user"} className="text-blue-500 hover:opacity-70 cursor-pointer text-[17px]">
                User Details
              </Link>
            )}
            {isInput && (
              <div className="flex gap-4">
                {selectedRows.length > 0 && (
                  <Space style={{ marginRight: 10 }}>
                    <div>Action: </div>
                    <UserPackageDropdown onSelect={handleSelectUserPackage} packageList={packageList} />
                  </Space>
                )}
                <div>
                  <SelectBox
                    options={selectOptions}
                    defaultSelected={selectOptions[0].key}
                    onSelect={handleSelect}
                    label="Filter by:"
                  />
                </div>
                <div>{renderFilterBar()}</div>
              </div>
            )}
          </Space>

          <UserTable
            data={tableData}
            packageList={packageList}
            loading={loading}
            onSelectChange={handleSelectChange}
            rowKeys={selectedRowKeys}
            onTableChange={handleTableChange}
            onStatusChange={handleStatusChange}
            onSelect={handleSelectPackage}
            columnsTable={columns}
            pag={pagination}
            onOpenModal={handleViewUser}
            onOpenEditModal={handleEditPropertyUser}
            dashboard={dashboard}
          ></UserTable>
        </Space>
      </Card>
      <UserModal
        title={modalTitle}
        packageList={packageList}
        isModalOpen={isModalOpen}
        onCloseModal={handleCloseModal}
        data={userData}
        type={modalType}
      />
      <UserEditModal
        modalAttrs={modalAttrs}
        onCloseModal={handleCloseEditModal}
        form={form}
        onSubmitForm={handleSubmitForm}
      />
      <AlertPopup isOpen={alertOpen} type={alertType} message={alertMsg} onCloseAlert={handleCloseAlert} />
    </div>
  );
};

export default UserManagement;
