import { Alert } from "antd";
import React, { useEffect, useState } from "react";

type AlertTypes = "info" | "warning" | "error" | "success";
interface AlertProps {
  isOpen?: boolean;
  type: string;
  message?: string;
  onCloseAlert: () => void;
  duration?: number;
}

const AlertPopup: React.FC<AlertProps> = ({ isOpen, type, message, onCloseAlert, duration }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(isOpen || false);
    if (isOpen) {
      setTimeout(() => {
        onCloseAlert();
      }, duration || 1500);
    }
  }, [isOpen, onCloseAlert]);

  return (
    <>
      {open && type && (
        <div className="fixed top-32 right-16">
          <Alert message={message || ""} type={type as AlertTypes} showIcon closable onClose={onCloseAlert} />
        </div>
      )}
    </>
  );
};

export default AlertPopup;
