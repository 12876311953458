import { DeleteOutlined, FormOutlined } from "@ant-design/icons";
import moment from "moment";

const dateFormat = "YYYY/MM/DD HH:mm:ss";

export const columns: any = ({ onOpenModal, onDeletePromotion }: any) => [
  {
    title: "Title",
    dataIndex: "title",
    key: "title",
    align: "left",
  },
  {
    title: "Content",
    dataIndex: "content",
    key: "content",
    align: "left",
    render: (content: string) => {
      return <div className="line-clamp-3">{content || ""}</div>;
    },
  },
  {
    title: "Created At",
    dataIndex: "createdAt",
    key: "createdAt",
    sorter: true,
    defaultSortOrder: "descend",
    render: (date: string) => {
      return <div>{moment(date).format(dateFormat)}</div>;
    },
  },
  {
    title: "Expired At",
    dataIndex: "expiredAt",
    key: "expiredAt",
    sorter: true,
    render: (date: string) => {
      return <div>{moment(date).format(dateFormat)}</div>;
    },
  },
  {
    title: "Action",
    key: "action",
    align: "center",
    render: (record: any) => {
      return (
        <div className="flex space-x-3 justify-center">
          <FormOutlined onClick={() => onOpenModal(record)} />
          <DeleteOutlined onClick={() => onDeletePromotion(record)} />
        </div>
      );
    },
  },
];

export interface PromotionType {
  _id?: string;
  title: string;
  content: string;
  createdAt: string;
  expiredAt: string;
  status: number;
}

export interface ModalType {
  isOpen: boolean;
  title: string;
  type: string;
}

export const initModalData = {
  title: "",
  content: "",
  expiredAt: "",
  status: 1,
};

export const initModalAttrs = {
  isOpen: false,
  title: "",
  type: "add",
};
