import { Button, Modal, Space } from "antd";
import React from "react";

interface AppProps {
  title: string;
  data: any;
  isOpen: boolean;
  onClose: () => void;
  type: string;
}

const UserModal: React.FC<AppProps> = ({ title, data, isOpen, onClose, type }) => {
  const imageData = () => (
    <Space size="large" align="start">
      {data ? (
        <Space direction="vertical" size="middle">
          <img src={data?.imageUrl} className="w-full h-full" alt="style" />
          <div>
            <p>
              <b>Prompt: </b>
              {data.prompt || ""}
            </p>
            <p>
              <b>Status: </b>
              {data.status === 1 ? "Public" : "Private"}
            </p>
            <p>
              <b>Styles: </b>
              {data.styleImage && data.styleImage.filter((img: string) => img && img !== "undefined").length > 0
                ? data.styleImage.filter((img: string) => img && img !== "undefined").join(", ")
                : ""}
            </p>
            <p>
              <b>Number of like: </b>
              {data.numberOfLike || 0}
            </p>
            <p>
              <b>Owner: </b>
              {data.owner ? `${data.owner.name || ""} (${data.owner.email || ""})` : ""}
            </p>
          </div>
        </Space>
      ) : (
        <p>Data not found</p>
      )}
    </Space>
  );

  const loadContent = () => {
    switch (type) {
      case "view":
        return imageData();

      default:
        break;
    }
  };

  return (
    <Modal
      title={title || "Modal title"}
      open={isOpen}
      closable={false}
      footer={[
        <Button key="submit" type="primary" onClick={onClose}>
          Close
        </Button>,
      ]}
      onOk={onClose}
      onCancel={onClose}
    >
      {loadContent()}
    </Modal>
  );
};

export default UserModal;
