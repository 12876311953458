export const convertParams = (params: any, optionSelected: string, selectOptions: any[]) => {
  const passParams: any = {};
  if (!optionSelected) {
    passParams[selectOptions[0].key] = params.filterValue || "";
  } else {
    passParams[optionSelected] = params.filterValue;
  }
  if (optionSelected !== "status") {
    delete passParams.status;
  }
  if (params.sortField && params.sortOrder) {
    passParams.sort = params.sortField;
    passParams.type = params.sortOrder === "ascend" ? -1 : 1;
  }
  passParams.limit = params.pageSize;
  passParams.page = params.current;
  return passParams;
};
