import { Tabs } from "antd";
import React, { memo, useMemo } from "react";
import { AlertPopup } from "../../../components/common";
import { getAboutUs } from "../../../helpers/api/func";
import useQuery from "../../../hooks/useQuery";
import FormAboutUs from "./_components/formAbout";

const AboutusManagement = () => {
  const { data, openAlert, error, setOpenAlert } = useQuery(getAboutUs);
  const memozied = useMemo(() => data, [data]);
  const item = [
    {
      label: "Sotatek",
      key: "item-1",
      children: <FormAboutUs data={memozied?.[0]} length={4} />,
    },
    {
      label: "Dokpik AI",
      key: "item-2",
      children: <FormAboutUs data={memozied?.[1]} length={2} />,
    },
  ];
  return (
    <div className="rounded-xl w-full h-full bg-white px-6 drop-shadow-lg shadow-md">
      <Tabs items={item} />
      <AlertPopup
        isOpen={openAlert}
        duration={3000}
        type={"error"}
        message={error}
        onCloseAlert={() => setOpenAlert(false)}
      />
    </div>
  );
};

export default memo(AboutusManagement);
