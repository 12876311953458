import React from "react";
import { UserOutlined } from "@ant-design/icons";
import { Dropdown, Avatar } from "antd";
import type { MenuProps } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import { logoutUser } from "../../store/actions";

const ProfileDropdown: React.FC = () => {
  const dispatch = useDispatch();

  const logOut = () => {
    dispatch(logoutUser());
  };

  const { isUserLogout } = useSelector((state: any) => ({
    isUserLogout: state.Login.isUserLogout,
  }));

  if (isUserLogout) {
    return <Navigate to="/signin" />;
  }

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: <a onClick={(e) => e.preventDefault()}>Profile</a>,
      disabled: true,
    },
    {
      key: "2",
      label: <Link to="/change-password">Change Password</Link>,
    },
    {
      type: "divider",
    },
    {
      key: "3",
      label: <a onClick={logOut}>Log out</a>,
    },
  ];

  return (
    <div className="mx-6">
      <Dropdown menu={{ items }} placement="bottomRight">
        <a onClick={(e) => e.preventDefault()}>
          <Avatar size="default" icon={<UserOutlined />} />
        </a>
      </Dropdown>
    </div>
  );
};

export default ProfileDropdown;
