import { Form, Input, Modal, Select, SelectProps, DatePicker } from "antd";
import React, { useEffect, useState } from "react";
import { getAllHashtag } from "../../../helpers/api/func";

interface Props {
  onCloseModal?: () => void | undefined;
  modalAttrs?: any;
  form?: any;
  getObjectModal?: any;
}
const TrendingModal = (prop: Props) => {
  const { onCloseModal, modalAttrs, getObjectModal, form } = prop;

  const { RangePicker } = DatePicker;
  const [options, setOptions] = useState<SelectProps["options"]>([]);
  const callfechHashtag = async () => {
    const result = await getAllHashtag();

    if (result?.message === "ok") {
      const data = result.data.filter(
        (item: any, index: any) => result.data.map((vl: any) => vl.hashtag).indexOf(item.hashtag) === index,
      );
      setOptions(
        data.map((value: any) => {
          return { value: value.hashtag };
        }),
      );
    }
  };
  useEffect(() => {
    callfechHashtag();
  }, []);
  const onFinish = (values: any) => {
    const { hashtagList, picker } = values;
    const handleDate = (index: number) => picker?.map((value: any) => value._d.toLocaleDateString("zh-Hans-CN"))[index];
    const valueConvertDate = {
      hashtagList,
      startDate: handleDate(0),
      endDate: handleDate(1),
    };
    getObjectModal && getObjectModal(valueConvertDate);
  };

  return (
    <Modal
      open={modalAttrs.isOpen}
      onCancel={onCloseModal}
      title={modalAttrs.title}
      onOk={(res) => {
        form.validateFields().then((values: any) => {
          onFinish(values);
        });
      }}
    >
      <Form
        form={form}
        name="modifyPackage"
        layout="vertical"
        // initialValues={initModalData}
        // onFinish={onSubmitForm}
        autoComplete="off"
        onFinish={onFinish}
      >
        <Form.Item label="HashTag" name="hashtagList" rules={[{ required: true, message: "Title is required" }]}>
          <Select
            mode="multiple"
            allowClear
            placeholder="Please select"
            // defaultValue={["#a10", "#c12"]}
            options={options}
          />
        </Form.Item>
        <Form.Item label="Expired At" name="picker" rules={[{ required: true, message: "Content is required" }]}>
          <RangePicker />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default TrendingModal;
