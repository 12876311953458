import React from "react";
import { Modal, Form, Input, InputNumber, Button, Select } from "antd";
import { initModalData, ModalType, PackageType } from "./package.config";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { TYPE_PACKAGE_MAP } from "../../../constants/common.constant";

interface PackageProps {
  modalAttrs: ModalType;
  onCloseModal: () => void;
  form: any;
  onSubmitForm: (values: PackageType) => void;
}

const PackageModal: React.FC<PackageProps> = ({ modalAttrs, onCloseModal, form, onSubmitForm }) => {
  const { Option } = Select;

  return (
    <Modal
      title={modalAttrs.title}
      open={modalAttrs.isOpen}
      onCancel={onCloseModal}
      onOk={() => {
        form.validateFields().then((values: PackageType) => {
          onSubmitForm(values);
        });
      }}
    >
      <Form
        form={form}
        name="modifyPackage"
        layout="vertical"
        initialValues={initModalData}
        onFinish={onSubmitForm}
        autoComplete="off"
      >
        <Form.Item label="Title" name="title" rules={[{ required: true, message: "Title is required" }]}>
          <Input />
        </Form.Item>
        <Form.List name="content">
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields.map((field, index) => (
                <Form.Item label={index === 0 ? "Content" : ""} required={false} key={field.key}>
                  <div className="flex items-center">
                    <Form.Item
                      {...field}
                      validateTrigger={["onChange", "onBlur"]}
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: "Please input content or delete this field.",
                        },
                      ]}
                      noStyle
                    >
                      <Input />
                    </Form.Item>
                    <div className="w-8 text-center">
                      {fields.length > 1 ? (
                        <MinusCircleOutlined className="dynamic-delete-button" onClick={() => remove(field.name)} />
                      ) : null}
                    </div>
                  </div>
                </Form.Item>
              ))}
              <Form.Item>
                <Button type="dashed" onClick={() => add()} style={{ width: "60%" }} icon={<PlusOutlined />}>
                  Add content
                </Button>
                <Form.ErrorList errors={errors} />
              </Form.Item>
            </>
          )}
        </Form.List>
        <Form.Item
          name="typePackage"
          className="w-48"
          label="Type Package"
          rules={[{ required: true, message: "Please choose type package" }]}
        >
          <Select placeholder="Please choose type package">
            {TYPE_PACKAGE_MAP.map((type: any) => (
              <Option key={type.value} value={type.value}>
                {type.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="No. Expired Days"
          name="numberOfExpDays"
          rules={[{ required: true, message: "No. Expired Days is required" }]}
        >
          <InputNumber min={0} precision={0} />
        </Form.Item>
        <Form.Item
          label="No. of generated per day"
          name="timesGenPerDay"
          rules={[{ required: true, message: "Times Generate Per Day is required" }]}
        >
          <InputNumber min={-1} precision={0} />
        </Form.Item>
        <Form.Item
          label="No. of edit per day"
          name="timesEditPerDay"
          rules={[{ required: true, message: "No. of edit per day is required" }]}
        >
          <InputNumber min={-1} precision={0} />
        </Form.Item>
        <Form.Item
          label="Adopter price"
          name="adopterPrice"
          rules={[{ required: true, message: "Adopter price  is required" }]}
        >
          <InputNumber min={0} prefix="VND" />
        </Form.Item>
        <Form.Item
          label="Total Price"
          name="totalPrice"
          rules={[{ required: true, message: "Total Price  is required" }]}
        >
          <InputNumber min={0} prefix="VND" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default PackageModal;
