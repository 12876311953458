import { call, put, takeEvery } from "redux-saga/effects";

import { LOGIN_USER, LOGOUT_USER } from "./actionTypes";
import { apiError, loginSuccess, loginUser, logoutUserSuccess } from "./actions";

import { adminSignin } from "../../../helpers/api/func";

interface ResponseProps {
  adminSignin: (email: string, password: string) => void;
  data: { accessToken: string; expireTime: number };
}

function* loginUserSaga({ payload }: ReturnType<typeof loginUser>) {
  try {
    const { user, navigate } = payload;
    const response: ResponseProps = yield call(adminSignin, {
      email: user.email,
      password: user.password,
    });
    const {
      data: { accessToken, expireTime },
    } = response;

    localStorage.setItem(
      "authUser",
      JSON.stringify({ accessToken, expireTime: expireTime ? Date.now() + expireTime * 1000 : null }),
    );
    yield put(loginSuccess(response));
    navigate("/");
  } catch (error: any | undefined) {
    yield put(apiError(error));
  }
}

function* logoutUserSaga() {
  try {
    localStorage.removeItem("authUser");
    yield put(logoutUserSuccess());
  } catch (error: any | undefined) {
    yield put(apiError(error));
  }
}
function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUserSaga);
  yield takeEvery(LOGOUT_USER, logoutUserSaga);
}

export default authSaga;
