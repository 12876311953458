export const timeConvert = (time?: string) => {
  if (time) {
    const timeCreated = new Date(time);
    const year = String(timeCreated.getFullYear()).padStart(2, "0");
    const month = String(timeCreated.getMonth() + 1).padStart(2, "0");
    const date = String(timeCreated.getDate()).padStart(2, "0");
    const hours = String(timeCreated.getHours()).padStart(2, "0");
    const minutes = String(timeCreated.getMinutes()).padStart(2, "0");
    const seconds = String(timeCreated.getSeconds()).padStart(2, "0");
    return `${date}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  } else {
    return "";
  }
};
