import { Avatar, Space } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { UserType } from "../defineType";
import moment from "moment";
import { PAYMENT_METHOD_MAP, PAYMENT_STATUS_MAP } from "../../../constants/transaction";
import { SelectBox } from "../../../components/common";
import { timeConvert } from "../../../utils/helpers";

export const columns: any = ({ onSelect, onOpenModal }: any) => [
  {
    title: "User",
    dataIndex: "userId",
    key: "userId",
    width: 300,
    render: (user: any) => {
      return (
        <div className="flex gap-2 items-center ">
          <Space>
            {user.thumbnailUrl ? (
              <Avatar className="shadow-xl" size={40} src={user?.thumbnailUrl || ""} />
            ) : (
              <Avatar className="shadow-xl" size={40} icon={<UserOutlined />} />
            )}
          </Space>
          <div>
            <p className="m-0 font-bold">{user?.name}</p>
            <p className="m-0 opacity-50 font-medium">{user?.email}</p>
          </div>
        </div>
      );
    },
  },
  {
    title: "Purchase date",
    dataIndex: "createdAt",
    sorter: true,
    key: "createdAt",
    render: (createdAt: string, record: UserType) => {
      return <div>{timeConvert(createdAt)}</div>;
    },
  },
  {
    title: "Package",
    dataIndex: "packageId",
    key: "packageId",
    render: (pg: any) => <div>{pg?.title}</div>,
  },
  {
    title: "Price",
    dataIndex: "price",
    render: (status: number, data: any) => {
      return <div>{status.toLocaleString("it-IT", { style: "currency", currency: "VND" })}</div>;
    },
  },
  {
    title: "CodeBank",
    dataIndex: "messageCodeBank",
    render: (messageCodeBank: number) => <div>{messageCodeBank || "-"}</div>,
  },
  {
    title: "Payment status",
    dataIndex: "payStatus",
    sorter: true,
    align: "center",
    width: 200,
    key: "payStatus",
    render: (payStatus: number, record: any) => {
      if (payStatus === 1) return <div className="text-center w-[160px] text-[#36CA7A]">Success</div>;
      if (payStatus === 0) return <div className="text-center w-[160px] text-[#F75E5E]">Failed</div>;
      return (
        <SelectBox
          btnSize="middle"
          options={[
            { key: "0", label: "Failed" },
            { key: "2", label: "Processing" },
            { key: "1", label: "Success" },
          ]}
          defaultSelected={String(PAYMENT_STATUS_MAP.find((e) => e.value === payStatus)?.value)}
          onSelect={(key: string) => onSelect(key, record)}
        />
      );
    },
  },
  {
    title: "Payment Methods",
    dataIndex: "payMethod",
    sorter: true,
    key: "payMethod",
    render: (payMethod: number) => <div>{PAYMENT_METHOD_MAP.find((e) => e.value === payMethod)?.label}</div>,
  },
  {
    title: "Payment proof",
    dataIndex: "proofImage",
    key: "proofImage",
    render: (proofImage?: string) => (
      <Space size="middle">
        {proofImage && (
          <div className="cursor-pointer" onClick={() => onOpenModal(proofImage)}>
            <img src={proofImage} className="w-28 h-28 shadow-xl object-cover" alt="img" />
          </div>
        )}
      </Space>
    ),
  },
];
