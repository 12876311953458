import { externalTooltipHandler } from "./plugins";

export type ChartItemProps = {
  label: string;
  value: number;
};

export const optionChartBar: any = {
  indexAxis: "y",
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      grid: {
        display: false,
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
  },
};

export const pluginsChartBar: any = [
  {
    afterDraw: (chart: any) => {
      if (chart.tooltip?._active?.length) {
        const y = chart.tooltip._active[0].element.y;
        const xAxis = chart.scales.x;
        const meta = chart.getDatasetMeta(0);
        const height = meta.data[0].height;
        const ctx = chart.ctx;
        ctx.save();
        ctx.beginPath();
        ctx.moveTo(xAxis.left, y);
        ctx.lineTo(xAxis.right, y);
        ctx.lineWidth = height;
        ctx.strokeStyle = "rgba(64, 81, 137,0.1)";
        ctx.stroke();
        ctx.restore();
      }
    },
  },
];

export const optionLineChart = {
  aspectRatio: 3,
  interaction: {
    mode: "index" as const,
    intersect: false,
  },
  scales: {
    y: {
      display: true,
      grid: {
        display: true,
      },
    },
    x: {
      grid: {
        display: false,
      },
      ticks: {
        autoSkip: true,
        maxTicksLimit: 8,
        stepSize: 5,
        tickWidth: 30,
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
      external: externalTooltipHandler,
    },
    // tooltip: {
    //   backgroundColor: "#e6e6ea",
    //   titleColor: "black",
    //   titleMarginBottom: 10,
    //   bodyColor: "black",
    //   padding: 15,
    //   caretPadding: 10,
    //   caretSize: 0,
    //   displayColors: false,
    // },
  },
};

export const pluginsLineChart: any = [
  {
    afterDraw: (chart: any) => {
      if (chart.tooltip?._active?.length) {
        const x = chart.tooltip._active[0].element.x;
        const yAxis = chart.scales.y;
        const ctx = chart.ctx;
        ctx.save();
        ctx.beginPath();
        ctx.setLineDash([10, 5]);
        ctx.moveTo(x, yAxis.top);
        ctx.lineTo(x, yAxis.bottom);
        ctx.lineWidth = 2;
        ctx.strokeStyle = "rgba(64, 81, 137,0.1)";
        ctx.stroke();
        ctx.restore();
      }
    },
  },
];
