import { Card, Space } from "antd";
import React, { useEffect, useRef, useState } from "react";

import { AlertPopup, SearchBox, SelectBox } from "../../../components/common";
import { getAllContacts } from "../../../helpers/api/func";
import { IParamImage } from "../defineType";
import { columns } from "./contact.config";
import ContactTable from "./table";

const INIT_PAGINATION_VALUES = {
  current: 1,
  pageSize: 10,
  total: 1,
};

const INIT_PARAM_VALUES = { limit: 10, page: 1 };

const SEARCH_FIELD_OPTIONS = [
  { key: "firstName", label: "First Name", default: true },
  { key: "lastName", label: "Last name" },
  { key: "email", label: "Email" },
];

const DEFAULT_SELECTED_OPTION = SEARCH_FIELD_OPTIONS.find((option: any) => option.default)?.key;

const ContactManagement: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [contactList, setContactList] = useState([]);
  const [paginations, setPagination] = useState<IParamImage>(INIT_PAGINATION_VALUES);
  const [param, setParam] = useState<any>(INIT_PARAM_VALUES);

  const alertType = useRef<string>("");
  const alertMsg = useRef<string>("");
  const selectedValue = useRef<string>(DEFAULT_SELECTED_OPTION ?? "");

  const handleTableChange = async (pagination: any, sorter: any, extra: any) => {
    setParam({
      ...param,
      limit: pagination?.pageSize,
      page: pagination?.current,
      sort: extra?.field,
      type: extra?.order === "ascend" ? -1 : 1,
    });
    try {
      setPagination({ ...paginations, current: pagination?.current, pageSize: pagination?.pageSize });
    } catch (error: any) {
      alertType.current = "error";
      alertMsg.current = "Failed to load data, please try again";
      setAlertOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseAlert = () => {
    onResetAlert();
  };

  const onResetAlert = () => {
    alertType.current = "";
    alertMsg.current = "";
    setAlertOpen(false);
  };

  const fetchContact = async () => {
    setLoading(true);
    try {
      const result = await getAllContacts(param);
      const {
        data: { listContacts },
      } = result;
      if (listContacts) {
        const contacts = listContacts.map((list: object, index: number) => {
          return { ...list, key: index };
        });
        setContactList(contacts);
      }

      const {
        data: { total },
      } = result;
      if (total !== undefined) {
        setPagination({ current: param.page, pageSize: param.limit, total: total });
      } else {
        setPagination({ current: 1, pageSize: 10, total: 0 });
      }
    } catch (error) {
      alertType.current = "error";
      alertMsg.current = "Failed to load data, please try again";
      setAlertOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitSearch = (keyword: string) => {
    const newParam = { ...param, ...INIT_PARAM_VALUES };
    const listKeySelect = SEARCH_FIELD_OPTIONS.map((item: any) => item.key);
    for (const key in newParam) {
      if (listKeySelect.includes(key)) {
        delete newParam[key];
      }
    }
    newParam[`${selectedValue.current}`] = keyword;
    setParam(newParam);
  };

  const handleSelect = (value: string) => {
    selectedValue.current = value;
  };

  useEffect(() => {
    fetchContact();
  }, [param]);

  return (
    <div className="relative">
      <Card>
        <Space direction="horizontal" size="middle" className="mb-4 flex justify-between">
          <h1 className="font-bold text-xl">Contact Table</h1>
          <div className="flex gap-4">
            <div>
              <SelectBox
                options={SEARCH_FIELD_OPTIONS}
                defaultSelected={DEFAULT_SELECTED_OPTION}
                onSelect={handleSelect}
                label="Filter by:"
              />
            </div>
            <div>
              <SearchBox onSubmitSearch={handleSubmitSearch} size="large" />
            </div>
          </div>
        </Space>
        <ContactTable
          data={contactList}
          columnsTable={columns}
          loading={loading}
          pag={paginations}
          onTableChange={handleTableChange}
        />
      </Card>
      <AlertPopup
        isOpen={alertOpen}
        type={alertType.current}
        message={alertMsg.current}
        onCloseAlert={handleCloseAlert}
      />
    </div>
  );
};

export default ContactManagement;
