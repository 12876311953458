import { Card } from "antd";
import { ChartData } from "chart.js";
import React, { useLayoutEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { AlertPopup } from "../../../components/common";
import { getDataBarChart } from "../../../helpers/api/func";
import useQuery from "../../../hooks/useQuery";
import { ChartItemProps, optionChartBar, pluginsChartBar } from "./DashBoards.config";

const BarChart: React.FC = () => {
  const [chartBar, setChartBar] = useState<ChartData<"bar">>();
  const { data = [], loading, error, openAlert, setOpenAlert } = useQuery(getDataBarChart);

  useLayoutEffect(() => {
    setChartBar({
      labels: data.length > 0 ? data.map((item: ChartItemProps) => item.label || "") : [],
      datasets: [
        {
          label: "Users Gained",
          data: data.length > 0 ? data.map((item: ChartItemProps) => item.value || "") : [],
          backgroundColor: ["rgb(59, 130, 246)"],
          borderRadius: 8,
        },
      ],
    });
  }, [loading]);
  const handleCloseAlert = () => {
    setOpenAlert(false);
  };
  return (
    <Card className="rounded-xl  row-span-1 w-[40%]">
      <div className="text-center text-[16px] font-semibold uppercase mb-6">
        Statistics on the number of users in per package
      </div>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
          {chartBar ? (
            <Bar data={chartBar} options={optionChartBar} plugins={pluginsChartBar} />
          ) : (
            <p>Data not found</p>
          )}
        </>
      )}
      <AlertPopup isOpen={openAlert} type={"error"} message={"Cannot get data"} onCloseAlert={handleCloseAlert} />
    </Card>
  );
};

export default BarChart;
