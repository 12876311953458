import { Space } from "antd";
import { dollarUS } from "../../../helpers/common/currency";
import { DeleteOutlined, FormOutlined } from "@ant-design/icons";

export interface PackageType {
  id?: string;
  content: string;
  numberOfExpDays: number;
  timesGenPerDay: number;
  title: string;
  totalPrice: number;
  expiredDays?: any;
  startDays?: any;
}

export interface ModalType {
  isOpen: boolean;
  title: string;
  type: string;
}

export const columns: any = ({ onOpenModal, onDeletePackage }: any) => [
  {
    title: "Title",
    dataIndex: "title",
    key: "title",
  },
  {
    title: "Content",
    dataIndex: "content",
    key: "content",
  },
  {
    title: "No. of generated/day",
    dataIndex: "timesGenPerDay",
    key: "timesGenPerDay",
    render: (times: number) => <span>{times === -1 ? "Unlimited" : times}</span>,
  },
  {
    title: "No. of edit/day",
    dataIndex: "timesEditPerDay",
    key: "timesEditPerDay",
    align: "center",
    render: (times: number) => <span>{times === -1 ? "Unlimited" : times}</span>,
  },
  {
    title: "Adopter price",
    dataIndex: "adopterPrice",
    key: "adopterPrice",
    align: "right",
    render: (adopterPrice: number) => (
      <span>{adopterPrice.toLocaleString("it-IT", { style: "currency", currency: "VND" })}</span>
    ),
  },
  {
    title: "Total Price",
    dataIndex: "totalPrice",
    key: "totalPrice",
    align: "right",
    render: (totalPrice: number) => (
      <span>{totalPrice.toLocaleString("it-IT", { style: "currency", currency: "VND" })}</span>
    ),
  },

  {
    title: "Action",
    key: "action",
    align: "center",
    render: (record: any) => {
      return (
        <div className="flex space-x-3">
          <FormOutlined onClick={() => onOpenModal(record)} />
          <DeleteOutlined onClick={() => onDeletePackage(record)} />
        </div>
      );
    },
  },
];

export const initModalData = {
  content: "",
  title: "",
  typePackage: 0,
};

export const initModalAttrs = {
  isOpen: false,
  title: "",
  type: "add",
};
