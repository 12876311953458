import { Switch } from "antd";
import React from "react";

interface AppProps {
  defaultChecked?: boolean;
  onSwitch: (checked: boolean) => void;
  loading?: boolean;
  checkedText?: string;
  unCheckedText?: string;
}

const SwitchBox: React.FC<AppProps> = ({ defaultChecked, onSwitch, loading, checkedText, unCheckedText }) => {
  return (
    <Switch
      defaultChecked={defaultChecked || true}
      onChange={(checked) => onSwitch(checked)}
      loading={loading || false}
      checkedChildren={checkedText || ""}
      unCheckedChildren={unCheckedText || ""}
    />
  );
};

export default SwitchBox;
