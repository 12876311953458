export const PARAMS_IMAGE = {
  current: 1,
  pageSize: 10,
  total: 1,
};

export const SELECT_OPTIONS = [
  { key: "prompt", label: "Prompt" },
  { key: "isPublic", label: "Status" },
];
