import React, { memo, useEffect, useMemo, useState } from "react";
import { Table } from "antd";
import { ImageProps } from "../defineType";

interface AppProps {
  data: any;
  loading: boolean;
  onTableChange: (pagination: object, sorter: any, extra: object) => void;
  columnsTable: any;
  pag: { current: number; pageSize: number; total: number };
  onOpenModal: (record: ImageProps) => void;
  onDeleteImage?: (record: any) => void;
}

const showTotal = (total: number, range: any) => {
  return `${range.join("-")} of ${total} items`;
};

const initPagination = {
  current: 1,
  pageSize: 10,
  total: 0,
  showSizeChanger: true,
  pageSizeOptions: ["10", "20", "30"],
  showTotal: showTotal,
};

const UserTable: React.FC<AppProps> = ({
  data,
  loading,
  onTableChange,
  columnsTable,
  pag,
  onOpenModal,
  onDeleteImage,
}) => {
  const [pagination, setPagination] = useState(initPagination);

  useEffect(() => {
    setPagination({ ...pagination, ...pag });
  }, [pag]);

  const columnsTables = useMemo(() => columnsTable({ onOpenModal, onDeleteImage }), []);

  return (
    <div className="overflow-x-auto">
      <Table
        columns={columnsTables}
        dataSource={data}
        loading={loading}
        onChange={(pagination, sorter, extra) => onTableChange(pagination, sorter, extra)}
        pagination={pagination}
      />
    </div>
  );
};

export default memo(UserTable);
