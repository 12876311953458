import React, { useEffect, useState } from "react";
import { Button, Checkbox, Form, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import LoginImg from "../../../assets/images/signup.json";
import { AlertPopup } from "../../../components/common";
import { useNavigate } from "react-router";
import { loginUser } from "../../../store/actions";
import styled from "styled-components";
import Lottie from "react-lottie-player";
import { Link } from "react-router-dom";

interface AccountProps {
  email: string;
  password: string;
}

const PasswordEl = styled(Input.Password)`
  padding: 0px;
  position: relative;
  input {
    padding: 4px 30px 4px 11px !important;
    & + span {
      position: absolute;
      width: 14px;
      height: 100%;
      right: 11px;
    }
  }
`;

const SignIn: React.FC = () => {
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [alertMsg, setAlertMsg] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loginState = useSelector((state: any) => state.Login);
  const [form] = Form.useForm();

  useEffect(() => {
    handleCloseAlert();
    const { error, loading } = loginState;
    form.setFields([]);
    if (!loading && error) {
      if (error && error.code === 400) {
        if (error.message.includes("email")) {
          form.setFields([
            {
              name: "email",
              errors: [error.message],
            },
          ]);
        }
        if (error.message.includes("password")) {
          form.setFields([
            {
              name: "password",
              errors: [error.message],
            },
          ]);
        }
      } else {
        setAlertOpen(true);
        setAlertType("error");
        setAlertMsg("Failed to connect server, please try again");
      }
    }
  }, [loginState]);

  const onFinish = (values: AccountProps) => {
    const { email, password } = values;
    handleSignin(email, password);
  };

  const handleSignin = async (email: string, password: string) => {
    dispatch(loginUser({ email, password }, navigate));
  };

  const handleCloseAlert = () => {
    setAlertOpen(false);
    setAlertType("");
    setAlertMsg("");
  };

  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "Your ${name} is not a valid email!",
    },
  };

  return (
    <>
      <div className="container mx-auto p-6 min-h-screen">
        <div className="container flex items-center justify-center flex-col md:flex-row">
          <div className="w-full md:w-1/2 h-fit">
            <Lottie loop animationData={LoginImg} play className="block w-4/5 md:w-full mb-8 md:mb-0 mx-auto" />
          </div>
          <div className="w-full md:w-1/2 h-fit pl-0 sm:pl-4 lg:pl-12">
            <Form
              form={form}
              name="basic"
              className="flex flex-col"
              onFinish={onFinish}
              autoComplete="off"
              layout="vertical"
              validateMessages={validateMessages}
              validateTrigger={["onSubmit"]}
            >
              <Form.Item
                label="Email"
                name="email"
                rules={[{ type: "email" }, { required: true, message: "Please input your email!" }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Password"
                name="password"
                rules={[{ required: true, message: "Please input your password!" }]}
              >
                <PasswordEl />
              </Form.Item>

              <Form.Item className="self-end">
                <Link className="login-form-forgot" to="/forgot-password">
                  Forgot password
                </Link>
              </Form.Item>

              <Form.Item className="self-center">
                <Button className="login-form-button" type="primary" size="large" htmlType="submit">
                  Login
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
      <AlertPopup
        isOpen={alertOpen}
        duration={5000}
        type={alertType}
        message={alertMsg}
        onCloseAlert={handleCloseAlert}
      />
    </>
  );
};

export default SignIn;
