import { Button, Modal } from "antd";

interface Props {
  title: string;
  imgSrc: string | null;
  isOpen: boolean;
  onClose: () => void;
}

const ImageProofModal: React.FC<Props> = ({ imgSrc, isOpen, onClose, title }) => {
  return (
    <Modal
      title={title || "Modal"}
      open={isOpen}
      closable={false}
      footer={[
        <Button key="submit" type="primary" onClick={onClose}>
          Close
        </Button>,
      ]}
      onOk={onClose}
      onCancel={onClose}
    >
      {imgSrc && <img className="w-full h-full object-cover" src={imgSrc} alt="image proof" />}
    </Modal>
  );
};

export default ImageProofModal;
