import React from "react";
import { Navigate, Route } from "react-router-dom";
import { useProfile } from "../hooks/userHooks";

interface AuthProps {
  children: React.ReactNode;
}

const AuthProtected: React.FC<AuthProps> = ({ children }) => {
  const { userProfile, loading } = useProfile();

  if (!userProfile && loading) {
    return <Navigate to="/signin" />;
  }

  return <>{children}</>;
};

export { AuthProtected };
